<template>
	<div>
		<v-header></v-header>
		<v-banner></v-banner>
		
		<section>
			<div class="question">
				<div class="about-title">
					<h2>contact us</h2>
					<p class="title_cn">联系我们</p>
				</div>
			</div>
		
			<div class="background">
				<div class="container">
					<div class="contact clearfix">
						<div class="map" id="map">
							<img src="../../assets/images/map.png" width="100%" />
		
						</div>
		
						<div class="intro mapID" id="scroll">
							<ul>
								<li class="map-list on">
									<h2>湖南总部</h2>
									<p class="phone"><i></i><span>电话</span>：18612341234</p>
									<p class="address"><i></i><span>地址</span>：湖南省长沙市天心区新韶西路288号</p>
								</li>
								
							</ul>
						</div>
					</div>
				</div>
			</div>
		</section>
		
		
		<v-footer></v-footer>
	</div>
	
</template>

<script>
	import Header from '../../components/header.vue';
	import Hfooter from '../../components/Hfooter.vue';
	import Hbanner from '../../components/banner.vue';
	export default{
		name:'aboutcomtactus',
		components:{
			'v-header':Header,
			'v-footer':Hfooter,
			'v-banner':Hbanner
			}
	};
</script>

<style>
</style>
