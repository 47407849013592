<template>

  <section class="solution">
    <div class="table container">
      <div class="table-cell">
        <div class="three-steps">
          <div class="solutionTitle">
            <h2>教育解决方案</h2>
            <h3>Smart Solution</h3>
          </div>

          <div class="line"></div>
          <p>多年来，我们致力于扎根行业、深入场景进行产品设计和创新，为行业用户构建端到端的解决方案，为客户创造新价值</p>
        </div>
        <div class="solution-item">
          <ul>
            <li class="solution-item-list" @click="goto('/Teach')">
              <i></i>
              <div>
                <strong>教学考务</strong>
                <p>提高教学考务管理工作效率</p>
              </div>
            </li>

            <li class="solution-item-list" @click="goto('/Exam')">
              <i></i>
              <div>
                <strong>在线考试</strong>
                <p>建立无纸化考试通道， 打造专业定制化服务</p>
              </div>
            </li>

            <li class="solution-item-list" @click="goto('/School')">
              <i></i>
              <div>
                <strong>在线教学</strong>
                <p>多样化的互动教学,功能丰富。</p>
              </div>
            </li>

            <li class="solution-item-list" @click="goto('/EI')">
              <i></i>
              <div>
                <strong>决策分析</strong>
                <p>提高高校管理人员决策的及时性及准确率</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
  export default{
    name:'solution',
    methods:{
      goto(url){
        location.href=url;
      }

    }
  };
</script>

<style lang="stylus" scoped>


</style>
