<template>
	<div>
		<v-header  class="headerTop"></v-header>
		<div class="listWOW">
			<div class="row">
				<div class="slideInLeft">
					<div class="wow rollIn"><img src="../../assets/images/desktopCalendar_03.png" /></div>
				</div>
				<div class="slideInRight">
					<div class="wow bounceInDown"><img src="../../assets/images/desktopCalendar_06.png" /></div>
					<div class="wow lightSpeedIn cursorHand  wowMargin"><img src="../../assets/images/desktopCalendar_10.png" /></div>
				</div>
			</div>
			<div class="row" style="background-color: #5090bd;">
				<div class="slideInLeft">
					<div class="wow bounceInLeft"><img src="../../assets/images/desktopCalendar_15.png" /></div>
				</div>
				<div class="slideInRightN">
					<div class="wow flipInX"><img  src="../../assets/images/desktopCalendar_18.png" /></div>
				</div>
			</div>
			<div class="row" style="background-color: #7aafd3;">
				<div class="slideInRight">
					<div class="wow bounceInLeft"><img  src="../../assets/images/desktopCalendar_26.png" /></div>
				</div>
				<div class="slideInLeft">
					<div class="wow bounceInRight"><img  src="../../assets/images/desktopCalendar_23.png" /></div>
				</div>
				
			</div>
			<div class="row-column bgimg">
					<div class="wow bounceInDown columnW-max"><img src="../../assets/images/desktopCalendar_31.png" /></div>
					<div class="wow bounceInUp  wowMargin columnW-min cursorHand" @click="OApath()"> <img src="../../assets/images/desktopCalendar_35.png" /></div>
			</div>
			
		</div>
		<v-footer></v-footer>
	</div>
	
</template>

<script>
	import Header from '../../components/header';
	import Hfooter from '../../components/Hfooter';
	export default{
		name:'desktopCalendar',
		components:{
			'v-header':Header,
			'v-footer':Hfooter
		}
	};
</script>

<style lang="stylus" scoped="scoped">
	.bgimg{
		background :url(../../assets/images/desktopCalendar_39.png) no-repeat center bottom #5090bd;
		background-size :55% 50%;
	}
</style>
