<template>
	<div>
		<v-header class="headerTop" ></v-header>
		<div class="listWOW">
			<div class="row">
				<div class="slideInLeft">
					<div class="wow rollIn"><img src="../../assets/images/andriodimg_03.png" /></div>
				</div>
				<div class="slideInRight">
					<div class="wow bounceInDown"><img src="../../assets/images/andriodimg_06.png" /></div>
					<div class="wow lightSpeedIn cursorHand  wowMargin"><img src="../../assets/images/andriodimg_10.png" /></div>
				</div>
			</div>
			<div class="row" style="background-color: #5090bd;">
				<div class="slideInLeft">
					<div class="wow bounceInLeft"><img src="../../assets/images/andriodimg_15.png" /></div>
				</div>
				<div class="slideInRightN flipInX">
					<div class="wow flipInX"><img src="../../assets/images/andriodimg_18.png" /></div>
				</div>
			</div>
			<div class="row" style="background-color: #7aafd3;">
				<div class="slideInRight">
					<div class="wow bounceInLeft"><img src="../../assets/images/andriodimg_26.png" /></div>
				</div>
				<div class="slideInLeft">
					<div class="wow bounceInRight"><img src="../../assets/images/andriodimg_23.png" /></div>
				</div>
			</div>
			<div class="row-column" style="background-color: #5090bd;">
				<div class="row">
					<div class="wow bounceInLeft"><img src="../../assets/images/andriodimg_31.png" /></div>
					<div class="wow bounceInRight "><img src="../../assets/images/andriodimg_31-1.png" width="80%" /></div>
				</div>
				<div class="wow  wowMargin columnW-min cursorHand"><img src="../../assets/images/andriodimg_35.png" /></div>
			</div>
		</div>
		<v-footer></v-footer>
	</div>
	
</template>

<script>
	import Header from '../../components/header';
	import Hfooter from '../../components/Hfooter';
	export default{
		name:'andriodContvol',
		components:{
			'v-header':Header,
			'v-footer':Hfooter
		}
	};
</script>

<style>
</style>
