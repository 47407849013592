<template>
	<div>
		<v-header   class="headerTop"></v-header>
		<div class="listWOW">
			<div class="row">
				<div class="slideInLeft">
					<div class="wow rollIn"><img src="../../assets/images/shopping_03.png" /></div>
				</div>
				<div class="slideInRight">
					<div class="wow bounceInDown"><img src="../../assets/images/shopping_06.png" /></div>
					<div class="wow lightSpeedIn cursorHand  wowMargin" @click="shopPath()"><img src="../../assets/images/shopping_10.png" /></div>
				</div>
			</div>
			<div class="row" style="background-color: #50afbd;">
				<div class="slideInLeft">
					<div class="wow bounceInLeft"><img src="../../assets/images/shopping_15.png" /></div>
				</div>
				<div class="slideInRightN">
					<div class="wow lightSpeedIn"><img src="../../assets/images/shopping_18.png" /></div>
				</div>
			</div>
			<div class="row" style="background-color: #97ddd9;">
				<div class="slideInRightN">
					<div class="wow bounceInLeft"><img src="../../assets/images/shopping_26.png" /></div>
				</div>
				<div class="slideInLeft">
					<div class="wow bounceInRight "><img src="../../assets/images/shopping_23.png" /></div>
				</div>
			</div>
			<div class="row-column bgimg" >
				<div class="wow bounceInDown "><img src="../../assets/images/shopping_32.png" /></div>
				<div class="wow bounceInUp  wowMargin columnW-min cursorHand" @click="shopPath()">
					<img src="../../assets/images/shopping_33.png" />
				</div>
			</div>
		</div>

		<v-footer></v-footer>
	</div>
</template>

<script>
	import Header from '../../components/header';
	import Hfooter from '../../components/Hfooter.vue';

	export default {
		name: 'shopping',
		components: {
			'v-header': Header,
			'v-footer': Hfooter
			},
		methods:{
			shopPath(){
				var shopUrl = 'http://39.108.49.151:8089/index';
				// window.location.href = shopUrl;
				window.open(shopUrl, '_blank');//在新的页面打开
			}
		}
	};
</script>
<style lang="stylus" scoped="scoped">
	.bgimg{
		background: url(../../assets/images/shopping_31.png) no-repeat center #50afbd;
		background-size: 50% 100%;
	}
</style>
