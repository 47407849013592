<template>
  <div>
    <v-header></v-header>
    <v-banner></v-banner>

    <section>
      <div class="question">
        <div class="about-title">
          <h2>{{newsGroup.groupName}}</h2>
          <p class="title_cn">{{newsGroup.subTitle}}</p>
        </div>
      </div>

      <div class="background">
        <div class="exhibi container">
          <div class="news-item">
            <ul class="clearfix">
              <li class="news-list" @click="listClick(item.id)" v-for="(item, index) in newsList.slice(0,4)"
                  :key="index" style="background: url('images/solution-3.jpg') no-repeat center/cover">

                <a href="javascript:;">
                  <div class="mask"></div>
                </a>
                <a href="javascript:;">
                  <div class="font">
                    <div class="date">
                      <i></i>
                      {{item.createTime}}
                    </div>
                    <h3>{{item.newsTitle}}</h3>
                    <p>{{item.newsIntroduction}}</p>
                  </div>
                </a>
              </li>


            </ul>
          </div>

          <div class="exhibi-item">
            <ul class="clearfix">
              <li class="exhibi-item-list" v-for="(item, index) in newsList.slice(4)" :key="index">
                <div>
                  <div class="date"><i></i>
                    {{item.createTime}}
                  </div>
                  <a href="javascript:;">
                    <h3>{{item.newsTitle}}</h3>
                  </a>
                  <p>{{item.newsIntroduction}}</p>
                  <div class="more"><a href="javascript:;">MORE</a></div>
                  <div class="line"></div>
                </div>
              </li>
            </ul>
          </div>
          <!-- page分页 -->


          <el-pagination

            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryParams.pageNum"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="queryParams.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>

        </div>
      </div>
    </section>


    <v-footer></v-footer>
  </div>
</template>

<script>
  import Header from '../../components/header.vue';
  import Hfooter from '../../components/Hfooter.vue';
  import banner from '../../components/banner.vue';

  import {getNewsList, getNewsGroupInfo} from '../../api/pageApi';
  import util from '../../utils/utils';


  export default {
    name: 'NewsList',
    components: {
      'v-header': Header,
      'v-footer': Hfooter,
      'v-banner': banner
    },
    props: {},
    data() {
      return {
        newsList: [],
        groupId: 0,
        page: 1, //总页数
        limit: 2, // 当前条数
        active: 1, //当前页面
        newsGroup: {},
        total: 0,
        queryParams: {
          pageSize: 10,
          pageNum: 1,
        },

      };
    },
    created() {
      this.getNewsGroup();
      this.getList();

    },
    methods: {
      handleSizeChange(val) {
        //console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        this.listPage.pageIndex = val
        this.getList();
      },
      //获取新闻分类
      getNewsGroup() {
        this.groupId = this.$route.query.id
        getNewsGroupInfo(this.groupId).then(res => {
          this.newsGroup = res.data;
        })
      },
      //获取新闻列表
      getList() {
        this.groupId = this.$route.query.id;
        this.queryParams.groupId = this.groupId;
        getNewsList(this.queryParams).then(res => {
          this.newsList = res.rows;
          this.total = res.total;

          for (let i = 0; i < this.newsList.length; i++) {
            var newsLists = this.newsList[i];
            newsLists.createTime = util.dateTimeFormatter(newsLists.createTime);
          }
        })
      },
      listClick(id) {
        this.$router.push('/News?id=' + id)
      }
    },


    watch: {
      $route(to) {
        this.newsId = to.query.id;
        this.getNewsGroup();
        this.getList();
      }
    },

    mounted() { //页面初始化方法
    }
  };
</script>

<style lang="stylus" scoped="scoped">
  @import '../../assets/css/news.css'
</style>
