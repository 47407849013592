<template>
	<!-- 移动端 -->
	<section class="mobile-tab">
		<div class="mobile-swiper-container">
			<div class="swiper-wrapper">
				<div class="swiper-slide" style="">
					<div>
						<div class="pro"></div>
						<div class="intro">
							<h2></h2>
							<h3>TSCloud教育多媒体应用管理平台</h3>
						</div>
						<div class="more">集音视频交互、多点互动、智能跟踪、课程直点播等应用于一体,在教育云平台上实现优质资源的共享,有效推动教育信息化的快速落地。</div>
					</div>
				</div>
				<div class="swiper-slide" style="">
					<div>
						<div class="pro"></div>
						<div class="intro">
							<h2></h2>
							<h3>一校带多校</h3>
						</div>
						<div class="more">一校带多校解决方案所提供的多媒体、超文本、远程信息传递等手段能够提供信息时代的基本教育环境，实现教育资源的跨时空传输，为师资力量薄弱的地区提供优质教学资源，促进优质教育资源共享，消除“信息贫困”。</div>
					</div>
				</div>
				<div class="swiper-slide" style="">
					<div>
						<div class="pro"></div>
						<div class="intro">
							<h2></h2>
							<h3>精品课堂录制、多机位跟踪</h3>
						</div>
						<div class="more">五机位豪华录播教室、双目跟踪机制、全向吊顶麦克风阵列、平板电脑导播、控制可无缝升级互动功能。</div>
					</div>
				</div>
				<div class="swiper-slide" style="">
					<div>
						<div class="pro"></div>
						<div class="intro">
							<h2></h2>
							<h3>远程互动教研</h3>
						</div>
						<div class="more">“国培计划”“乡村教师支持计划”等政策，明确要求通过远程的方式提供大规模的教学教研培训计划，提升特别是中西部地区以及偏远山区教师的培训次数及质量，到2020年做到全体乡村360学时的远程培训。</div>
					</div>
				</div>
				<!-- <div class="swiper-slide" style="">
			<div>
				<div class="pro"></div>
				<div class="intro">
					<h2></h2>
					<h3>班班录</h3>
				</div>
				<div class="more"><a href="">了解更多</a></div>
			</div>
		</div> -->
			</div>
			<div class="swiper-pagination"></div>
		</div>
	</section>

	
</template>

<script>
	export default {
		name:'mobletab'
	};
</script>

<style>
</style>
