<template>
	<div>
		<v-header></v-header>
		<v-banner></v-banner>

		<section>
			<div class="News">
				<div class="maintenance container">
					<div class="title">
						<h2 v-html="newsData.newsTitle"></h2>
					</div>
					<div class="date"><i></i>{{newsData.createTime}}</div>
					<div class="content" v-html="newsData.newsContent" style="text-align: left;">	</div>


				</div>
			</div>
		</section>

		<v-footer></v-footer>
	</div>


</template>

<script>
	import Header from '../../components/header.vue';
	import Hfooter from '../../components/Hfooter.vue';
	import banner from '../../components/banner.vue';

  // 通过axios调用 接口

  import { getNewsDetails } from '../../api/pageApi';
  import util from '../../utils/utils';


  export default {
    name: 'newsdeftails',
    components: {
      'v-header': Header,
      'v-footer': Hfooter,
      'v-banner': banner
    },
    data(){
      return{
        newId:0,
        newsData:{}
      };
    },
    created() {
      console.log(this.$route.query.id)

      this.getnewsD()
    },
    methods:{

      getnewsD(){

        var id = this.$route.query.id;
        getNewsDetails(id).then(res =>{
          this.newsData = res.data
          var newsTime = this.newsData.createTime
          this.newsData.createTime = util.dateTimeFormatter(newsTime);
        })
      }

    }


  };
</script>

<style>
</style>
