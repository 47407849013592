<template>
  <div>
    <v-header></v-header>
    <v-banner></v-banner>

    <section>
      <div class="question">
        <div class="about-title">
          <h2>Applications</h2>
          <p class="title_cn">文档案例</p>
        </div>
      </div>

      <div class="product application">
        <div class="container">
          <!-- <div class="product-type">
            <ul class="clearfix">
              <li><a href="javascript:;">案例视频</a></li>
              <li class="on"><a href="javascript:;">案例文档</a></li>
            </ul>
          </div> -->

          <div class="video-item">

            <div class="cont-left">
              <ul>
<!--                <li class="cont-left-list">-->
<!--                  <div class="pro">-->
<!--                    <img src="images/case-1.jpg">-->
<!--                  </div>-->
<!--                  <div class="detail">-->
<!--                    <a href="javascript:;">-->
<!--                      <h2>长沙市网络联校教学系统案例</h2>-->
<!--                    </a>-->
<!--                    <p>-->
<!--                      为贯彻落实党的十八届三中全会提出的“构建利用信息化手段扩大优质教育资源覆盖面的有效机制”精神，将优质教育资源覆盖至农村偏远学校，长沙市于2016年开展第二批基础教育“网络联校”建设工作，完成全市农村偏远学校全覆盖的建设目标，决定开展第二批网络联校建设项目。</p>-->
<!--                    <div class="more">-->
<!--                      <a href="javascript:;"><span>MORE</span></a></div>-->
<!--                  </div>-->
<!--                </li>-->

              </ul>
            </div>
            <!-- page分页 -->
            <div class="page">
            </div>
          </div>
        </div>
      </div>
    </section>

    <v-footer></v-footer>
  </div>
</template>

<script>
  import Header from '../../components/header.vue';
  import Hfooter from "../../components/Hfooter.vue";
  import Hbanner from '../../components/banner.vue';

  export default {
    name: 'servicefile',
    components: {
      'v-header': Header,
      'v-footer': Hfooter,
      'v-banner': Hbanner
    }

  };
</script>

<style scoped="scoped">

  @import '../../assets/css/product.css'


</style>
