<template>
	<div>
		<v-header></v-header>
		<v-banner></v-banner>
		
		
		<section>
			<div class="question">
				<div class="about-title">
					<h2>Careers</h2>
					<p class="title_cn">招贤纳士</p>
				</div>
			</div>
		
			<div class="career background">
				<div class="container">
					<ul class="clearfix">
						<li class="career-list">
							<h2>商务/渠道/公关助理（主管）</h2>
							<em>招聘要求</em>
							<p><span>专业：</span>不限</p>
							<p><span>学历：</span>专科以上</p>
							<p><span>工作地点：</span>南京</p>
							<p><span>招聘人数</span>：1名</p>
							<p>职责描述：</p>
							<p>商务接待及签约，渠道管理……</p>
							<a href="javascript:;"><div class="detail"><i>></i>查看详细</div></a>
						</li>
						<li class="career-list">
							<h2>区域销售经理</h2>
							<em>招聘要求</em>
							<p><span>专业：</span>不限</p>
							<p><span>学历：</span>不限</p>
							<p><span>工作地点：</span>山东、河北、内蒙古、新疆……</p>
							<p><span>招聘人数</span>：若干</p>
							<p>职责描述：</p>
							<p>负责公司所在区域的销售任务……</p>
							<a href="javascript:;"><div class="detail"><i>></i>查看详细</div></a>
						</li>
						<li class="career-list">
							<h2>产品经理</h2>
							<em>招聘要求</em>
							<p><span>专业：</span>计算机及多媒体通信相关行业</p>
							<p><span>学历：</span>专科以上</p>
							<p><span>工作地点：</span>南京</p>
							<p><span>招聘人数</span>：1名</p>
							<p>职责描述：</p>
							<p>产品调研、信息搜集等……</p>
							<a href="javascript:;"><div class="detail"><i>></i>查看详细</div></a>
						</li>
						<li class="career-list">
							<h2>技术支持工程师</h2>
							<em>招聘要求</em>
							<p><span>专业：</span>计算机及多媒体通信相关行业</p>
							<p><span>学历：</span>专科以上</p>
							<p><span>工作地点：</span>南京、河南、成都</p>
							<p><span>招聘人数</span>：若干</p>
							<p>职责描述：</p>
							<p>所在区域项目的技术支持工作……</p>
							<a href="javascript:;"><div class="detail"><i>></i>查看详细</div></a>
						</li>
						<li class="career-list">
							<h2>IT网管</h2>
							<em>招聘要求</em>
							<p><span>专业：</span>计算机或多媒体通信相关行业</p>
							<p><span>学历：</span>专科以上</p>
							<p><span>工作地点：</span>南京</p>
							<p><span>招聘人数</span>：1名</p>
							<p>职责描述：</p>
							<p>负责公司日常网络的维护……</p>
							<a href="javascript:;"><div class="detail"><i>></i>查看详细</div></a>
						</li>
					</ul>
					<div class="page"></div>
				</div>
			</div>
		</section>
		
		
		<v-footer></v-footer>
	</div>

</template>

<script>
	import Header from '../../components/header.vue';
	import Hfooter from '../../components/Hfooter.vue';
	import Hbanner from '../../components/banner.vue';
	
	export default{
		name:'aboutrecruit',
		components:{
			'v-header':Header,
			'v-footer':Hfooter,
			'v-banner':Hbanner
		}
	};
</script>

<style>
</style>
