<template>
  <!-- 监听子组件传过来的数据：v-on:iconSelected="iconSelected" -->
  <!-- <Header v-on:iconSelected="iconSelected"  class="Width-100"  :style="[{width:width + 'px'},{height:height + 'px'},{overflow:overflow +'px'}]"> -->

  <header class="Width-100">
    <!-- <div style="border: 1px solid #fff; height: 50px; color: #fff;" v-for="(item, index, key) in Htitle" :key="index">{{item
    }}</div> -->
    <div class="header-container clearfix change">
      <!-- 移动端按钮 -->
      <div class="nav-bar" :class="{'selected':isSelected}" @click="navSelected(styleObj)">
        <i class="icon-cross"></i>
        <i class="icon-cross"></i>
        <i class="icon-cross"></i>
      </div>
      <!-- logo -->
      <div class="logo-pro">
        <a href="javascript:;">
          <img class="responsive" :src="logo" alt="无忧果" title="无忧果">
        </a>
      </div>
      <!-- nav -->
      <div class="nav-box" :class="{'show' : isShow}">
        <div class="nav">
          <ul class="nav-item">
            <li class="nav-item-list " :class="{open: isopen === index}" @click="navlist(index)"
                v-for="(item, index, key ) in navnameList" :key="index">

              <a v-if="item.children !== undefined && item.children.length > 0" :href="item.url" data-hover="－" data-icon="＋">{{item.name}}</a>
              <a v-else :href="item.url" :target="item.target">{{item.name}}</a>

              <ul class="sub-nav" v-if="item.children !== undefined && item.children.length > 0">
                <li class="sub-nav-list" v-for="(child, key) in item.children" :key="child.index">

                  <a  v-if="child.type=='Method'"  href="javascript:void(0)" @click="child.method(this)" class="">{{child.name}}</a>
                  <a  v-else-if="child.type=='CallMethod'"  href="javascript:void(0)" @click="callMethods(child.method)" class="">{{child.name}}</a>
                  <router-link v-else="child.type!='Method'" :to="{path:child.url, params: {id:child.id}}">{{child.name}}</router-link>



                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="other" style="display:none;">
          <form>
            <ul>
              <li class="other-list search">
                <input type="text" name="">
                <button type="submit" value=""></button>
              </li>
            </ul>
          </form>
        </div>
      </div>
    </div>
  </header>
</template>

<script>


  //数据接口
  import {getAboutData, getNewsGroups} from '../api/pageApi.js';

  export default {
    name: 'homeHeader',
    props: {
      styleObj: {
        type: Object,
        default: () => {
        }
      }
    },
    data: function () {
      return {
        newtitle: [],
        isSelected: false,
        isShow: false,
        isopen: false,
        overflow: '',
        isstyleObj: false,
        logo: '',
        navnameList: [
          {
            id: '001',
            name: '首页',
            url: '/',
            target: '_self'
          },
          {
            id: '002',
            name: '产品中心',
            url: 'javascript:;',
            target: '',
            children:
              [
                {
                  id: '01',
                  name: '考试平台（开源）',
                  url: '/Exam',
                  target: '_self'
                },
                {
                  id: '02',
                  name: '教学考务系统（开源）',
                  url: '/Teach',
                  target: '_self'
                },
                // {
                //   id: '03',
                //   name: '低代码开发平台',
                //   url: '/Develop',
                //   target: '_self'
                // },

              ],

          },
          {
            id: '003',
            name: '解决方案',
            url: 'javascript:;',
            target: '_self',
            children:
              [
                {
                  id: '01',
                  name: '在线教学系统',
                  url: '/School',
                  target: '_self'
                },
                {
                  id: '02',
                  name: '高校场馆管理系统',
                  url: '/Stadium',
                  target: '_self'
                },

                {
                  id: '03',
                  name: '教育决策分析系统',
                  url: '/EI',
                  target: '_self'
                },

              ]
          },
          {
            id: '004',
            name: '服务与支持',
            url: '',
            target: '',
            children:
              [
                // {
                //   id: '01',
                //   name: '售后服务',
                //   url: '/serviceAftersales',
                //   target: '_self'
                // },
                // {
                // 	id: '002',
                // 	name: '视频案例',
                // 	url:'http://www.bubuko.com/infodetail-3728320.html',
                // 	target:'_self'
                // },
                // {
                //   id: '003',
                //   name: '文档案例',
                //   url: '/servicefile',
                //   target: '_self'
                // },
                {
                  id: '004',
                  name: '开发文档',
                  url: 'https://api.51shouyu.com/doc.html',
                  target: '_blank',
                  method:function(){
                    window.open("https://api.51shouyu.com/doc.html")
                  },
                  type:"Method"
                },
                {
                  id: '005',
                  name: '问题反馈',
                  url: '/Feedback',
                  target: '_blank',
                  method:'showFeedback',
                  type:"CallMethod"
                }
              ],

          },
          {
            id: '005',
            name: '资讯中心',
            url: 'javascript:;',
            target: '',
            // children:this.Htitle,
            children: []
          },

          {
            id: '006',
            name: '关于我们',
            target: '',
            url: 'javascript:;',
            children: []
          },
        ]
      };
    },

    mounted() {   //页面初始化方法

      //获取关于我们 分类
      this.getAbout();

      //获取新闻/资讯 分类
      this.getNews();
      this.getWebConfig();
    },

    methods: {  //监听方法click事件等，执行drawFeatures方法
      //获取公司新闻分类
      getAbout() {
        getAboutData().then(res => {

          //1 从 navnameList 取出 关于我们  数据项
          var navlist = {};
          for (let i = 0; i < this.navnameList.length; i++) {
            var navname = this.navnameList[i];
            if (navname.id == '006') {
              navlist = navname;
              break;
            }
          }

          //2 通过接口返回的数据，创建children菜单列表 到 menuList

          var menuList = [];
          for (let k = 0; k < res.rows.length; k++) {
            var item = res.rows[k];
            var menuI = {};

            menuI.id = item.id,
              menuI.name = item.title,
              menuI.url = '/aboutintroduce?id=' + item.id,
              menuI.target = '_self';

            menuList.push(menuI);
          }

          //3  将  menuList 赋值给 关于我们.children
          navlist.children = menuList;
        });
      },
      //获取新闻资讯分类
      getNews() {
        getNewsGroups().then(res => {

          //1，拿到 本地 资讯中心对应的数据
          var navNewList = {};
          for (let j = 0; j < this.navnameList.length; j++) {
            var newList = this.navnameList[j]
            if (newList.id == '005') {
              navNewList = newList
              break
            }
          }


          //2, 从接口拿到的数据 给到新的 数组中
          var resList = []
          for (let n = 0; n < res.rows.length; n++) {
            var resLT = res.rows[n];
            var resN = {};

            resN.id = resLT.id;
            resN.name = resLT.groupName;
            resN.titleEnglish = resLT.titleEnglish;
            // resN.url= "/NewsList?id=" + resLT.id + "&newsName=" + resLT.groupName + "&titleEnglist=" + resLT.titleEnglish;
            resN.url = "/NewsList?id=" + resLT.id;
            resN.target = '_self';

            resList.push(resN);
          }

          //3,将接口中新的数据给到 本地资讯中的子类中
          navNewList.children = resList;

        })

      },
      navSelected: function (styleObj) {

        var wsize = styleObj;
        console.log(wsize);

        // 取反
        this.isSelected = !this.isSelected;
        console.log(this.isSelected);

        // 修改列表的显示与隐藏
        if (this.isSelected === true) {
          this.isShow = this.isShow === false;
          console.log("我在00");
          this.$emit('wsizefn', wsize);
        } else {
          console.log("我不在00");
          this.isShow = this.isShow === false;

        }

      },
      navlist: function (i) { //i为参数，等同于index

        this.isopen = !this.isopen;

        if (this.isopen == true) {
          this.isopen = i; //当前的添加class,
        } else {
          this.isopen = false;
        }

      },
      getConfig(key,callback){
        this.getConfigKey(key).then(response => {
          console.log(typeof callback);
          callback(response.msg);
        });
      },
      getWebConfig(){
        this.getConfig('web.logo',(val)=>{
          this.logo=val;
        });
      },
      showFeedback(){
        this.$emit("feedback");
      },
      callMethods(methodName){
       if(methodName=='showFeedback'){
         this.showFeedback();
       }
      }
    }


  };
</script>

<style>
  .open {
    color: #3fb560;
  }
</style>
