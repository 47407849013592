import request from '@/utils/request';


//获取公司简介分类
export function getAboutData(data){
	return request({
		url:'/web/aboutus/noauth/list',
    method: 'get',
		params:data
	});
}

//获取公司简介分类--详情
export function getAboutDetails(data){
  return request({
    url:'/web/aboutus/noauth/' + data,
    method: 'get'
  });
}

//获取新闻、资讯分类
export function getNewsGroups(data){
  return request({
    url:'/news/group/noauth/list',
    method: 'get',
    data:data
  });
}

//获取新闻、资讯分类
export function getNewsGroupInfo(id){
  return request({
    url:'/news/group/noauth/'+id,
    method: 'get',
  });
}


//获取新闻--详情
export function getNewsDetails(id){
  return request({
    url:'/web/news/noauth/'+id,
    method: 'get',
  });
}

//获取新闻、资讯 列表
export function getNewsList(data){
  return request({
    url:'/web/news/noauth/list',
    method: 'get',
    params:data
  });
}










