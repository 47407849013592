import { render, staticRenderFns } from "./shopping.vue?vue&type=template&id=01362312&scoped=true&"
import script from "./shopping.vue?vue&type=script&lang=js&"
export * from "./shopping.vue?vue&type=script&lang=js&"
import style0 from "./shopping.vue?vue&type=style&index=0&id=01362312&lang=stylus&scoped=scoped&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01362312",
  null
  
)

export default component.exports