<template>
  <div class="footer">
    <div class="part-1 clearfix">
      <div class="footer-nav">
        <div class="left-item1 item-common" v-for="(item, index, key) in footerList" :key="index">
          <div class="ic-box">
            <span>{{item.name}}</span>
            <a :href="child.url" :target="child.target" v-for="(child,  key) in item.childer" :key="child.index">{{child.name}}</a>
          </div>
        </div>
      </div>

      <div class="footer-icon" >
        <div class="ic-box">
          <span>联系我们</span>
        </div>
        <div class="phone clearfix">
          <i class="L-float"></i>
          <strong class="L-float">0731-82299119</strong>
        </div>
        <div class="mail clearfix">
          <i class="L-float"></i>
          <strong class="L-float">湖南省长沙市雨花区红星国际B座</strong>
        </div>

        <div class="share">
          <ul class="clearfix">
            <li class="share-list" title="分享到新浪微博" onclick="shareTSina();"></li>
            <li class="share-list" title="分享到腾讯微博" onclick="shareWeibo()">
              <a></a>
            </li>
            <li class="share-list" title="分享到QQ" onclick="shareQzone()">
              <a></a>
            </li>
            <li class="share-list" title="" onclick="javascript:void(0);">
              <a href="" target="_blank"></a>
            </li>
          </ul>
        </div>

      </div>

      <div class="wechat">
        <div class="wechat-box">
          <img class="responsive" src="images/wechat_mp_qrcode.jpg">
          <p>关注我们</p>
        </div>
      </div>
    </div>

    <div class="footer-line"></div>

    <div class="copyright">

      <div class="copyright-box clearfix">
        <a href="http://beian.miit.gov.cn" target="_blank">
          <span>© IPC备案号：</span>
          <span>{{ICPNumber}}</span>
        </a>
      </div>

      <div class="copyright-box clearfix">
        <a href="javascript:;" target="_blank">
          <span>Copyright All Right Rerseved. {{CompanyName}} @2022 </span>
        </a>
      </div>

    </div>
  </div>
</template>

<script>

  //数据接口
  import {getAboutData, getNewsGroups} from '../api/pageApi.js';

  export default {
    name: 'Hfooter',
    data() {
      return {
        ICPNumber: '',
        CompanyName: '',
        footerList: [
          {
            id: '001',
            name: '产品中心',
            url: '',
            target: '',
            childer:
              [
                {
                  id: '01',
                  name: '考试平台（开源）',
                  url: '/Exam',
                  target: '_self'
                },
                {
                  id: '02',
                  name: '教学考务系统（开源）',
                  url: '/Teach',
                  target: '_self'
                },
              ]

          },
          {
            id: '002',
            name: '解决方案',
            target: '',
            url: '',
            childer:
              [
                {
                  id: '01',
                  name: '在线教学系统',
                  url: '/School',
                  target: '_self'
                },
                {
                  id: '02',
                  name: '高校场馆管理系统',
                  url: '/Stadium',
                  target: '_self'
                },

                {
                  id: '03',
                  name: '教育决策分析系统',
                  url: '/EI',
                  target: '_self'
                },

              ]

          },
          {
            id: '003',
            name: '资讯中心',
            target: '',
            url: '',
            childer: [],

          },
          {
            id: '004',
            name: '服务与支持',
            target: '',
            url: '',
            childer:
              [
                // {
                //   id: '01',
                //   name: '售后服务',
                //   target: '_self',
                //   url: '/serviceAftersales'
                // },
                // {
                //   id: '02',
                //   name: '视频案例',
                //   target: '_self',
                //   url: ''
                // },
                // {
                //   id: '03',
                //   name: '文档案例',
                //   target: '_self',
                //   url: 'servicefile'
                // },
                {
                  id: '004',
                  name: '开发文档',
                  url: 'https://api.51shouyu.com/doc.html',
                  target: '_blank',
                  method:function(){
                    window.open("https://api.51shouyu.com/doc.html")
                  },
                  type:"Method"
                },
                {
                  id: '005',
                  name: '问题反馈',
                  url: '/Feedback',
                  target: '_blank',
                  method:'showFeedback',
                  type:"CallMethod"
                }
              ]
          },
          {
            id: '005',
            name: '关于我们',
            target: '',
            url: '',
            childer: []
          }
        ],
        bttomtitle: []
      };
    },

    mounted() {
      this.getMenuinfo();
      this.getNewsinfo();
      this.getWebConfig();
    },
    methods: {
      getMenuinfo() {
        getAboutData().then(res => {

          //1，取出关于我们
          var aboutList = {}
          for (let a = 0; a < this.footerList.length; a++) {
            var abouts = this.footerList[a]
            if (abouts.id == '005') {
              aboutList = abouts
              break
            }
          }

          //2，数据重新组合
          var aboutNew = []
          for (let n = 0; n < res.rows.length; n++) {
            var aboutN = res.rows[n]
            var aboutNlist = []
            aboutNlist.id = aboutN.id;
            aboutNlist.name = aboutN.title;
            aboutNlist.url = '/aboutintroduce?id=' + aboutN.id;
            aboutNlist.target = '';

            aboutNew.push(aboutNlist)
          }

          //3,新数据给到关于我们
          aboutList.childer = aboutNew

        })

      },

      getNewsinfo() {

        getNewsGroups().then(res => {
          console.log(res)
          //1，获取资讯
          var fnewsList = []
          for (let i = 0; i < this.footerList.length; i++) {
            var newsinfo = this.footerList[i]
            if (newsinfo.id == '003') {
              fnewsList = newsinfo
              break
            }
          }

          //2, 数据重组
          var fresnews = []
          for (let j = 0; j < res.rows.length; j++) {
            var resNews = res.rows[j]
            var resnewsList = []
            resnewsList.id = resNews.id;
            resnewsList.name = resNews.groupName;
            resnewsList.url = "/NewsList?id=" + resNews.id;
            resnewsList.target = '';
            fresnews.push(resnewsList)
          }

          //3，新数据给资讯
          fnewsList.childer = fresnews

        })

      },

      getConfig(key, callback) {
        this.getConfigKey(key).then(response => {
          console.log(typeof callback);
          callback(response.msg);
        });
      },
      getWebConfig() {
        this.getConfig('web.icp.number', (val) => {
          this.ICPNumber = val;
        });

        this.getConfig('sys.company.name', (val) => {
          this.CompanyName = val;
        });

      },

    }
  };
</script>

<style>
</style>
