<template>
  <section class="banner">

    <div class="swiper-container">
      <div class="swiper-wrapper">





        <div class="swiper-slide banner-list1 banner-list banner-item2"  @click="goto('/Teach')"
             style="background: url(img/banner_bg_01.jpg) no-repeat center;background-size:100% cover;">
          <div class="ani-container middle-center" @click="goto('/park')">
            <div class="bannerFlex">
              <div class="flexLeft">
                <h2 class="ani ani1-1 banner02"><img src="@/assets/images/product/teach/teach_01.png"/></h2>
              </div>
              <div class="flexRight" style="margin-top: -2rem">
                <strong class="ani ani1-2"><img src="@/assets/images/product/teach/home/teach-title.png"></strong>
                <p class="ani ani1-3"><img src="@/assets/images/product/teach/home/teach-title1.png"></p>
                <p class="ani ani1-3"><img src="@/assets/images/product/teach/home/teach-title2.png"></p>
                <!-- <div class="more ani ani1-3"><a target="_banlk" href="javascript:;">了解更多</a></div> -->
              </div>
            </div>
            <div></div>
          </div>
        </div>



        <div class="swiper-slide banner-list1 banner-list " @click="goto('/Exam')"
             style="background: url(img/banner_bg_02.jpg) no-repeat center;background-size:100% cover;">
          <div class="ani-container middle-center" @click="goto('/park')">
            <div class="bannerFlex">
              <div class="flexLeft">
                <h2 class="ani ani1-1 banner02"><img src="@/assets/images/product/exam/exam_02.png"/></h2>
              </div>
              <div class="flexRight" style="margin-top: -2rem">
                <strong class="ani ani1-2"><img src="@/assets/images/product/exam/home/exam-title.png"></strong>
                <p class="ani ani1-3"><img src="@/assets/images/product/exam/home/exam-title1.png"></p>
                <p class="ani ani1-3"><img src="@/assets/images/product/exam/home/exam-title2.png"></p>
                <!-- <div class="more ani ani1-3"><a target="_banlk" href="javascript:;">了解更多</a></div> -->
              </div>
            </div>
            <div></div>
          </div>
        </div>



        <div class="swiper-slide banner-list1 banner-list banner-item3"  @click="goto('/EI')"
             style="background: url(img/banner_bg_01.jpg) no-repeat center;background-size:100% cover;">
          <div class="ani-container middle-center" @click="goto('/park')">
            <div class="bannerFlex">
              <div class="flexLeft">
                <h2 class="ani ani1-1 banner02"><img src="@/assets/images/solution/ei/ei_04.png"/></h2>
              </div>
              <div class="flexRight" style="margin-top: -2rem">
                <strong class="ani ani1-2"><img src="@/assets/images/solution/ei/home/ei-title.png"></strong>
                <p class="ani ani1-3"><img src="@/assets/images/solution/ei/home/ei-title1.png"></p>
                <p class="ani ani1-3"><img src="@/assets/images/solution/ei/home/ei-title2.png"></p>
                <!-- <div class="more ani ani1-3"><a target="_banlk" href="javascript:;">了解更多</a></div> -->
              </div>
            </div>
            <div></div>
          </div>
        </div>




      </div>
      <!-- Add Pagination -->
      <div class="swiper-pagination"></div>
    </div>
  </section>

</template>

<script>
  export default {
    name: 'homebanner',
    data: function () {
      return {
        bannerList: [{}]
      };
    },
    methods:{
      goto(url){
        location.href=url;
      }
    }
  };
</script>

<style lang="stylus" scoped="scoped">
  .bannerFlex {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center

  }

  .flexRight, .flexLeft {
    margin: 0 10%;
  }

  .flexRight strong {
    margin-bottom: 0.3125rem;
  }

  .banner02 img {
    width: 80%
  }

</style>
