import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '../views/Home.vue';

//关于我们
import aboutculture from '../views/About/about-culture.vue';
import aboutintroduce from '../views/About/about-introduce.vue';
import aboutrecruit from '../views/About/about-recruit.vue';
import aboutcontactus from '../views/About/about-contactUS.vue';

//服务与支持
import servicefile from '../views/Service/serviceFile.vue';
import serviceAftersales from '../views/Service/serviceAftersales.vue';
import serviceDevelopment from '../views/Service/servicedevelopment.vue';


//解决方案
import shop from '../views/Solution/shopping.vue';
import OAenterprise from '../views/Solution/OAenterprise.vue';
import Homeintellect from '../views/Solution/Homeintellect.vue';

//产品中心
import AndroidContvol from '../views/Product/andriodContvol.vue';
import DesktopCalendar from '../views/Product/desktopCalendar.vue';


//资讯新闻
import News from '../views/News/News.vue';
import NewsList from '../views/News/NewsList.vue';
// import logins from '../components/login.vue';


Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {  //企业文化
    path: '/aboutculture',
    name: 'culture',
    component: aboutculture
  },
  { //公司简介
    path: '/aboutintroduce',
    name: 'aboutintroduce',
    component: aboutintroduce
  },
  { //招贤纳士
    path: '/aboutrecruit',
    name: 'aboutrecruit',
    component: aboutrecruit
  },
  { //联系我们
    path: '/aboutcontactus',
    name: 'aboutcontactus',
    component: aboutcontactus
  },
  { //行业新闻
    path: '/NewsList',
    name: 'NewsList',
    component: NewsList
  },
  { //文档案例
    path: '/servicefile',
    name: 'servicefile',
    component: servicefile
  },
  { //售后服务
    path: '/serviceAftersales',
    name: 'serviceAftersales',
    component: serviceAftersales
  },
  { //开发文档
    path: '/serviceDevelopment',
    name: 'servicedevelopment',
    component: serviceDevelopment
  },
  { //详情页
    path: '/News',
    name: 'News',
    component: News
  },
  { //在线商城
    path: '/shop',
    name: 'shopping',
    component: shop
  },
  { //在线教学
    path: '/School',
    name: 'School',
    component: () => import("@/views/Solution/School")
  },
  { //在线教学
    path: '/Stadium',
    name: 'Stadium',
    component: () => import("@/views/Solution/Stadium")
  },

  { //在线教学
    path: '/EI',
    name: 'EI',
    component: () => import("@/views/Solution/EI")
  },


  {  //企业OA
    path: '/OAenterprise',
    name: 'OAenterprise',
    component: OAenterprise
  },
  { //智能家控
    path: '/Homeintellect',
    name: 'Homeintellect',
    component: Homeintellect
  },


  {
    path: '/Exam',
    name: '/Exam',
    meta: {title: '考试平台（开源）'},
    component: () => import("@/views/Product/exam")
  },
  {
    path: '/Teach',
    name: '/Teach',
    meta: {title: '教学考务系统（开源）'},
    component: () => import("@/views/Product/teach")
  },


];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});


export default router;
