<template>
	<div>
		<v-header  style="position: absolute; z-index: 9; background-color: #0b1540; "></v-header>
		<div class="listWOW">
			<div class="row">
				<div class="slideInLeft">
					<div class="wow rollIn"><img src="../../assets/images/homeIntellect_03.png" /></div>
				</div>
				<div class="slideInRight">
					<div class="wow bounceInDown"><img src="../../assets/images/homeIntellect_06.png" /></div>
					<div class="wow lightSpeedIn cursorHand  wowMargin"  @click="homepath()"><img src="../../assets/images/homeIntellect_10.png" /></div>
				</div>
			</div>
			<div class="row" style="background: #50afbd;">
				<div class="slideInLeft leftImg">
					<div class="wow bounceInLeft"><img src="../../assets/images/homeIntellect_15.png" /></div>
					<div class="wow rollIn"><img src="../../assets/images/homeIntellect_17.png" /></div>
				</div>
				<div class="slideInRight">
					<div class="wow flipInX"><img src="../../assets/images/homeIntellect_19.png" /></div>
				</div>
			</div>
			<div class="row-column" style="background-color: #97ddd9;">
					<div class="wow bounceInDown homeImg">
						<img src="../../assets/images/homeIntellect_25.png" />
						<img src="../../assets/images/homeIntellect_27.png" />
					</div>
					<div class="wow pulse homeImg">
						<img src="../../assets/images/homeIntellect_31.png" />
						<img src="../../assets/images/homeIntellect_32.png" />
					</div>
					
					<div class="wow bounceInUp  wowMargin columnW-min cursorHand" @click="homepath()"> <img src="../../assets/images/OA_33.png" /></div>
			</div>
		</div>
		<v-footer></v-footer>
	</div>
</template>

<script>
	import Header from '../../components/header';
	import Hfooter from '../../components/Hfooter.vue';
	export default{
		name:'Homeintellect',
		components:{
			'v-header': Header,
			'v-footer': Hfooter
		},
		methods:{
			homepath(){
				var homeUrl = 'http://www.baidu.com.cn';
				window.open(homeUrl, '_blank');
			}
		}
	};
</script>

<style>
	.leftImg{
		display: flex;
		flex-flow: row nowrap; 
	}
	.homeImg img{
		width: 18%;
		margin: 3% 3% 0 3%;
	}
</style>
