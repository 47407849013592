<template>
	<div>
		<v-header></v-header>
		<v-banner></v-banner>


		<section>
			<div class="question">
				<div class="about-title">
					<h2>After-sales service</h2>
					<p class="title_cn">开发文档</p>
				</div>
			</div>

			<div class="service">
				<div class="container">

					<!-- <div class="tilte">单点登录接口</div> -->
					<div class="cont-text">
						<h2></h2>
						<p style="line-height: 16px;"><br /></p>
						<h2><span style="font-size: 16px; color: rgb(255, 0, 0);"><span style="font-size: 16px; font-family: &#39;等线 Light&#39;;">1.&nbsp;</span><strong><span
									style="font-size: 16px; font-family: &#39;等线 Light&#39;;">单点登录的应用场景</span></strong></span><br /></h2>
						<p><span style="font-size: 16px;"><strong><span style="font-size: 16px; font-family: &#39;等线 Light&#39;;"><img
										src="images/1504250874793951.jpg" title="1504250874793951.jpg" alt="开发文档流程图.jpg" /></span></strong></span></p>
						<p><span style="font-size: 16px;"><span style="font-family: 等线; font-size: 16px;">1.</span> <span style="font-family: 等线; font-size: 16px;"><span
									style="font-size: 16px; font-family: 等线;">用户登录</span>XX<span style="font-size: 16px; font-family: 等线;">平台门户。</span></span></span></p>
						<p><span style="font-size: 16px;"><span style="font-family: 等线; font-size: 16px;">2.</span> <span style="font-family: 等线; font-size: 16px;">XX平台保存用户会话信息，在浏览器COOKIE中保存用户TGC信息。</span></span></p>
						<p><span style="font-size: 16px;"><span style="font-family: 等线; font-size: 16px;">3.</span> <span style="font-family: 等线; font-size: 16px;"><span
									style="font-size: 16px; font-family: 等线;">用户在</span>XX平台选择应用，XX平台将用户重向至应用。</span></span></p>
						<p><span style="font-size: 16px;"><span style="font-family: 等线; font-size: 16px;">4.</span> <span style="font-family: 等线; font-size: 16px;"><span
									style="font-size: 16px; font-family: 等线;">用户在应用内访问受保护资源，应用将用户重定至</span>XX平台。</span></span></p>
						<p><span style="font-size: 16px;"><span style="font-family: 等线; font-size: 16px;">5.</span> <span style="font-family: 等线; font-size: 16px;">XX平台从浏览器中获取用户TGC信息，并根据TGC校验用户会话。</span></span></p>
						<p><span style="font-size: 16px;"><span style="font-family: 等线; font-size: 16px;">6.</span> <span style="font-family: 等线; font-size: 16px;">XX平台生成ST，并携带ST将用户从XX平台重定向至应用。</span></span></p>
						<p><span style="font-size: 16px;"><span style="font-family: 等线; font-size: 16px;">7.</span> <span style="font-family: 等线; font-size: 16px;"><span
									style="font-size: 16px; font-family: 等线;">应用使用</span>ST请求平台校验。</span></span></p>
						<p><span style="font-size: 16px;"><span style="font-family: 等线; font-size: 16px;">8.</span> <span style="font-size: 16px; font-family: 等线;">平台向应用返回校验结果及用户信息。</span></span></p>
						<p><br /></p>
						<h2><span style="font-size: 16px;"><span style="font-family: &#39;等线 Light&#39;;">1.1.&nbsp;</span><strong><span
									style="font-family: &#39;等线 Light&#39;;">单点登录配置</span></strong><strong><span style="font-family: &#39;等线 Light&#39;;"></span></strong></span></h2>
						<p style="text-indent:32px"><span style="font-family: 等线; color: rgb(255, 0, 0); font-size: 16px;">说明：以下给出的配置是通用的客户端配置Demo，只适合跳转地址固定的场景。如有其他特殊需求，需客户端程序自己实现。</span></p>
						<p><span style="font-family: 等线; font-size: 16px;">Java语言<span style="font-size: 16px; font-family: 等线;">客户端配置</span>(示例-ssotest
								项目)</span></p>
						<p style="line-height: 16px;"><img style="vertical-align: middle; margin-right: 2px;" src="images/icon_rar.gif"  /><a
							style="color: rgb(0, 102, 204); text-decoration: underline; font-size: 24px;" href="javascript:;" title="ssotest.zip"><span
								style="font-size: 24px;">ssotest.zip</span></a></p>
						<h3><span style="font-size: 16px; font-family: 等线;"><br /></span></h3>
						<h3><span style="font-size: 16px;"><span style="font-family: 等线;">1.1.1.&nbsp;</span><strong><span style="font-family: 等线;">&nbsp;ssotest
										项目说明</span></strong></span></h3>
						<p><span style="font-size: 16px;"><span style="font-family: 等线; font-size: 16px;">ssotest
									项目是由maven管理的javaweb项目，项目所依赖的jar文件包都在项目根目录下的pom.xml 文件内定义，文件内除</span><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">com.sanbu.shiro
									jar包外均为maven仓库的官方jar包。</span></span></p>
						<p style="text-align: center;"><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);"><img
								src="images/1500356689266324.png" title="1500356689266324.png" alt="2.png" /></span></p>
						<h3><span style="font-size: 16px;"><span style="font-family: 等线;">1.1.2.&nbsp;</span><strong><span style="font-family: 等线;">&nbsp;</span></strong><strong><span
									style="font-family: 等线;"><span style="font-family: 等线;">在项目</span>web.xml文件添加以下内容</span></strong><strong><span
									style="font-family: 等线;"></span></strong></span></h3>
						<p><span style="font-family: 等线; font-size: 16px;">登出配置</span></p>
						<p><img src="images/1500356745622305.png" title="1500356745622305.png" alt="3.png" /></p>
						<p><span style="font-size: 16px;"><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">shiroFilter</span><span
								style="font-size: 16px; font-family: 等线;">过滤器</span></span></p>
						<p><span style="font-family: 等线; font-size: 16px;"><img src="images/1500356788590170.png" title="1500356788590170.png"
								alt="5.png" /></span></p>
						<p><span style="font-family: 等线; font-size: 16px;">本过滤器用于过滤项目中需要使用单点登录的服务信息。</span></p>
						<p><br /></p>
						<h3><span style="font-size: 16px;"><span style="font-family: 等线;">1.1.3.&nbsp;</span><strong><span style="font-family: 等线;">&nbsp;Spring配置详见</span></strong><strong><span
									style="font-family: 等线;"></span></strong></span></h3>
						<p><span style="font-size: 16px;"><strong><span style="font-family: 等线; font-size: 21px;"><img src="images/1500356833485792.png"
										title="1500356833485792.png" alt="6.png" /></span></strong></span></p>
						<h3><span style="font-size: 16px;"><span style="font-family: 等线;">1.1.4.&nbsp;</span><strong><span style="font-family: 等线;">&nbsp;<span
										style="font-family: 等线;">配置</span>cas.properties</span></strong><strong><span style="font-family: 等线;"></span></strong></span></h3>
						<p><span style="font-size: 16px;"><strong><span style="font-family: 等线; font-size: 21px;"><img src="images/1500356867765292.png"
										title="1500356867765292.png" alt="7.png" /></span></strong></span></p>
						<p><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">参数说明</span></p>
						<table width="568">
							<tbody>
								<tr class="firstRow">
									<td width="73" valign="top" style="padding: 0px 7px; border-width: 1px; border-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">序号</span></p>
									</td>
									<td width="305" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-width: 1px; border-top-color: windowtext; border-bottom-width: 1px; border-bottom-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">参数名称</span></p>
									</td>
									<td width="189" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-width: 1px; border-top-color: windowtext; border-bottom-width: 1px; border-bottom-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">说明</span></p>
									</td>
								</tr>
								<tr>
									<td width="73" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">1</span></p>
									</td>
									<td width="305" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">cas.server.url.prefix</span></p>
									</td>
									<td width="189" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">单点登录认证服务地址</span></p>
									</td>
								</tr>
								<tr>
									<td width="73" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">2</span></p>
									</td>
									<td width="305" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">Cas.service</span></p>
									</td>
									<td width="189" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">客户端认证服务地址</span></p>
									</td>
								</tr>
								<tr>
									<td width="73" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">3</span></p>
									</td>
									<td width="305" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">login.url</span></p>
									</td>
									<td width="189" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">登陆拦截认证跳转地址</span></p>
									</td>
								</tr>
							</tbody>
						</table>
						<h3><span style="font-size: 16px;"><span style="font-family: 等线;">1.1.5.&nbsp;</span><strong><span style="font-family: 等线;">&nbsp;<span
										style="font-family: 等线;">取得用户的信息</span></span></strong><strong><span style="font-family: 等线;"></span></strong></span></h3>
						<p><span style="font-size: 16px;"><strong><span style="font-family: 等线;"><span style="font-family: 等线;"></span></span></strong></span></p>
						<p><span style="font-family: 等线;">用户登录后，可以通过接口取得用户信息，这些信息</span><span style="font-family: 等线; background: rgb(255, 255, 255);">包含在java.util.
								map对象中。</span><br /></p>
						<p style="text-indent:32px"><span style="font-size: 16px;"><em><span style="font-size: 16px; font-family: 等线;">例如：</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em><span style="color: rgb(128, 128, 128); font-size: 16px; font-family: 微软雅黑;">取得登录用户的信息</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><strong><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">import</span></em></strong><em><span
									style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">&nbsp;java.util.HashMap;</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><strong><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">import</span></em></strong><em><span
									style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">&nbsp;java.util.List;</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><strong><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">import</span></em></strong><em><span
									style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">&nbsp;java.util.Map;</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">&nbsp;</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><strong><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">import</span></em></strong><em><span
									style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">&nbsp;org.apache.shiro.subject.Subject;</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><strong><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">import</span></em></strong><em><span
									style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">&nbsp;org.apache.shiro.SecurityUtils;</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><strong><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">import</span></em></strong><em><span
									style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">&nbsp;org.apache.shiro.subject.PrincipalCollection;</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">&nbsp;</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><strong><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">public
											class</span></em></strong><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">&nbsp;LoginUserUtil
										{</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em> </em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em> </em><strong><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">public
											static </span></em></strong><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">Map
										getLoginUser(){</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em> </em><em> </em><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">Map&lt;?,
										?&gt; userMap = null;</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em> </em><em> </em><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">Subject
										subject = SecurityUtils.getSubject();</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em> </em><em> </em><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">PrincipalCollection
										principals = subject.getPrincipals();</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em> </em><em> </em><strong><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">if</span></em></strong><em><span
									style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">(principals!=null&amp;&amp;!principals.isEmpty()){</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em> </em><em> </em><em> </em><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">List&lt;?&gt;
										list=principals.asList();</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em> </em><em> </em><em> </em><strong><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">if</span></em></strong><em><span
									style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">(!list.isEmpty()){</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em> </em><em> </em><em> </em><em> </em><em><span
									style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">userMap = (Map&lt;?, ?&gt;)
										list.get(1);</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em> </em><em> </em><em> </em><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">}</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em> </em><em> </em><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">}</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em> </em><em> </em><strong><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">return</span></em></strong><em><span
									style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">&nbsp;userMap;</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em> </em><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">}</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em> </em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em> </em><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">...
										...</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">}</span></em></span></p>
						<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 16px;">&nbsp;</span></p>
						<p style="text-indent:32px"><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">数据说明:</span></p>
						<table width="533">
							<tbody>
								<tr class="firstRow">
									<td width="76" valign="top" style="padding: 0px 7px; border-width: 1px; border-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">序号</span></p>
									</td>
									<td width="173" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-width: 1px; border-top-color: windowtext; border-bottom-width: 1px; border-bottom-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">字段名（Map的Key）</span></p>
									</td>
									<td width="101" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-width: 1px; border-top-color: windowtext; border-bottom-width: 1px; border-bottom-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">类型</span></p>
									</td>
									<td width="183" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-width: 1px; border-top-color: windowtext; border-bottom-width: 1px; border-bottom-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">说明</span></p>
									</td>
								</tr>
								<tr>
									<td width="76" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">1</span></p>
									</td>
									<td width="173" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">id</span></p>
									</td>
									<td width="101" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">string</span></p>
									</td>
									<td width="183" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">用户识别<span style="font-size: 16px; font-family: Consolas; background: rgb(255, 255, 255);">ID</span></span></p>
									</td>
								</tr>
								<tr>
									<td width="76" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">2</span></p>
									</td>
									<td width="173" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">username</span></p>
									</td>
									<td width="101" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">string</span></p>
									</td>
									<td width="183" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">用户名</span></p>
									</td>
								</tr>
								<tr>
									<td width="76" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">3</span></p>
									</td>
									<td width="173" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">usertype</span></p>
									</td>
									<td width="101" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">string</span></p>
									</td>
									<td width="183" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">用户类型</span></p>
									</td>
								</tr>
								<tr>
									<td width="76" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">4</span></p>
									</td>
									<td width="173" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">schoolid</span></p>
									</td>
									<td width="101" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">string</span></p>
									</td>
									<td width="183" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">用户学校识别<span style="font-size: 16px; font-family: Consolas; background: rgb(255, 255, 255);">ID</span></span></p>
									</td>
								</tr>
								<tr>
									<td width="76" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">5</span></p>
									</td>
									<td width="173" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">platmark</span></p>
									</td>
									<td width="101" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">string</span></p>
									</td>
									<td width="183" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">用户平台识别<span style="font-size: 16px; font-family: Consolas; background: rgb(255, 255, 255);">ID</span></span></p>
									</td>
								</tr>
								<tr>
									<td width="76" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">6</span></p>
									</td>
									<td width="173" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">schoolgroupid</span></p>
									</td>
									<td width="101" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">string</span></p>
									</td>
									<td width="183" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px; background: rgb(255, 255, 255);">用户学校组识别<span style="font-size: 16px; font-family: Consolas; background: rgb(255, 255, 255);">id</span></span></p>
									</td>
								</tr>
							</tbody>
						</table>
						<p><span style="font-family: 等线; font-size: 16px;">&nbsp;</span></p>
						<h1><span style="font-size: 16px; color: rgb(255, 0, 0);"><span style="font-size: 16px; font-family: 等线;">2.&nbsp;</span><strong><span
									style="font-size: 16px; font-family: 等线;">接口参考</span></strong><strong><span style="font-size: 16px; font-family: 等线;"></span></strong></span></h1>
						<h2><span style="font-size: 16px;"><span style="font-family: &#39;等线 Light&#39;;">2.1.&nbsp;</span><strong><span
									style="font-family: &#39;等线 Light&#39;;">单点登录</span></strong><strong><span style="font-family: &#39;等线 Light&#39;;"></span></strong></span></h2>
						<h3><span style="font-size: 16px;"><span style="font-family: 等线;">2.1.1.&nbsp;</span><strong><span style="font-family: 等线;">单点登录接口</span></strong><strong><span
									style="font-family: 等线;"></span></strong></span></h3>
						<p style="text-indent:32px"><span style="font-family: 等线; font-size: 16px;"><span style="font-size: 16px; font-family: 等线;">单点登录接口用于显示登录界面，登录成功后，单点登录平台生成票据信息</span>ticket<span
								style="font-size: 16px; font-family: 等线;">，单点登录客户应用携带票据信息</span><span style="font-size: 16px; font-family: 宋体;">ticket</span><span
								style="font-size: 16px; font-family: 等线;">，并重定向到</span><span style="font-size: 16px; font-family: 宋体;">service</span><span
								style="font-size: 16px; font-family: 等线;">参数所指定的地址 &nbsp;。</span></span></p>
						<p><span style="font-size: 16px;"><strong><span style="font-size: 16px; font-family: 等线;">参数说明</span></strong></span></p>
						<table width="548">
							<tbody>
								<tr class="firstRow">
									<td width="47" valign="top" style="padding: 0px 7px; border-width: 1px; border-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">序号</span></p>
									</td>
									<td width="76" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-width: 1px; border-top-color: windowtext; border-bottom-width: 1px; border-bottom-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">参数名称</span></p>
									</td>
									<td width="76" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-width: 1px; border-top-color: windowtext; border-bottom-width: 1px; border-bottom-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">是否必须</span></p>
									</td>
									<td width="57" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-width: 1px; border-top-color: windowtext; border-bottom-width: 1px; border-bottom-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">类型</span></p>
									</td>
									<td width="293" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-width: 1px; border-top-color: windowtext; border-bottom-width: 1px; border-bottom-color: windowtext; background: rgb(238, 236, 225);">
										<p style="text-indent: 13px"><span style="font-family: 微软雅黑; font-size: 16px;">描述</span></p>
									</td>
								</tr>
								<tr>
									<td width="47" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">1</span></p>
									</td>
									<td width="76" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">service</span></p>
									</td>
									<td width="76" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p style="text-indent: 13px"><span style="font-family: 微软雅黑; font-size: 16px;">必选</span></p>
									</td>
									<td width="57" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">string</span></p>
									</td>
									<td width="293" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">登录成功后重定向的应用的目标地址即应用的入口地址。</span></p>
										<p><span style="font-family: 微软雅黑; font-size: 16px;">例如：http://ip:port/ssotest/</span></p>
									</td>
								</tr>
							</tbody>
						</table>
						<p style="margin-left:80px;text-indent:0"><span style="font-family: 微软雅黑; font-size: 16px;">&nbsp;</span></p>
						<p><span style="font-size: 16px;"><strong><span style="font-size: 16px; font-family: 等线;">返回说明</span></strong></span></p>
						<p><span style="font-family: 等线; font-size: 16px;">登录成功后获取票据信息ticket数据并缓存，然后重定向到目标地址。</span></p>
						<h3><span style="font-size: 16px;"><span style="font-family: 等线;">2.1.2.&nbsp;</span><strong><span style="font-family: 等线;">Ticket验证接口</span></strong><strong><span
									style="font-family: 等线;"></span></strong></span></h3>
						<p><span style="font-family: 等线; font-size: 16px;">验证Ticket，验证成功后并返回当前登录用户信息。</span></p>
						<p style="margin-left:48px;text-indent:28px"><span style="font-family: 等线; font-size: 16px;">&nbsp;</span></p>
						<p><span style="font-size: 16px;"><strong><span style="font-size: 16px; font-family: 等线;">接口说明</span></strong></span></p>
						<table width="540">
							<tbody>
								<tr class="firstRow">
									<td width="140" valign="top" style="padding: 0px 7px; border-width: 1px; border-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">url</span></p>
									</td>
									<td width="400" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-width: 1px; border-top-color: windowtext; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">http://10.1.53.100/middlecas/serviceValidate</span></p>
									</td>
								</tr>
								<tr>
									<td width="140" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">协议</span></p>
									</td>
									<td width="400" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">http</span></p>
									</td>
								</tr>
								<tr>
									<td width="140" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">请求方式</span></p>
									</td>
									<td width="400" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">post</span></p>
									</td>
								</tr>
								<tr>
									<td width="140" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; color: rgb(85, 85, 85); font-size: 16px; background: rgb(255, 255, 255);">格式</span></p>
									</td>
									<td width="400" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">xml</span></p>
									</td>
								</tr>
								<tr>
									<td width="140" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">接口方向</span></p>
									</td>
									<td width="400" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-size: 16px;"><span style="font-family: 微软雅黑; font-size: 14px;">应用</span><span style="font-family: Wingdings; font-size: 14px;">à</span><span
												style="font-family: 微软雅黑; font-size: 14px;">平台</span></span></p>
									</td>
								</tr>
							</tbody>
						</table>
						<p style="text-indent:28px"><span style="font-family: 宋体; font-size: 16px;">&nbsp;</span></p>
						<p style="text-indent:28px"><span style="font-family: 宋体; font-size: 16px;"><br /></span></p>
						<p><span style="font-size: 16px;"><strong><span style="font-size: 16px; font-family: 等线;">参数说明</span></strong></span></p>
						<table width="567">
							<tbody>
								<tr class="firstRow">
									<td width="47" valign="top" style="padding: 0px 7px; border-width: 1px; border-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">序号</span></p>
									</td>
									<td width="94" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-width: 1px; border-top-color: windowtext; border-bottom-width: 1px; border-bottom-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">参数名称</span></p>
									</td>
									<td width="76" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-width: 1px; border-top-color: windowtext; border-bottom-width: 1px; border-bottom-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">是否必须</span></p>
									</td>
									<td width="84" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-width: 1px; border-top-color: windowtext; border-bottom-width: 1px; border-bottom-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">类型</span></p>
									</td>
									<td width="265" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-width: 1px; border-top-color: windowtext; border-bottom-width: 1px; border-bottom-color: windowtext; background: rgb(238, 236, 225);">
										<p style="text-indent: 13px"><span style="font-family: 微软雅黑; font-size: 16px;">描述</span></p>
									</td>
								</tr>
								<tr>
									<td width="47" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">1</span></p>
									</td>
									<td width="94" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">ticket</span></p>
									</td>
									<td width="76" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p style="text-indent: 13px"><span style="font-family: 微软雅黑; font-size: 16px;">必选</span></p>
									</td>
									<td width="84" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">string</span></p>
									</td>
									<td width="265" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">单点登录成功后生成的票据信息</span></p>
									</td>
								</tr>
								<tr>
									<td width="47" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">2</span></p>
									</td>
									<td width="94" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">serivce</span></p>
									</td>
									<td width="76" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p style="text-indent: 13px"><span style="font-family: 微软雅黑; font-size: 16px;">必选</span></p>
									</td>
									<td width="84" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">string</span></p>
									</td>
									<td width="265" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">http://ip:port/ssotest/</span></p>
									</td>
								</tr>
							</tbody>
						</table>
						<p><span style="font-size: 16px;"><span style="font-family: 微软雅黑; font-size: 13px;">&nbsp;&nbsp;&nbsp;&nbsp;</span><span
								style="font-size: 16px; font-family: 微软雅黑;">例：</span></span></p>
						<p style="margin-left:13px;text-indent:28px"><span style="font-family: 微软雅黑; font-size: 13px;">http://10.1.53.100/middlecas/serviceValidate?ticket=SO6YEWI93093UTYDVXBZ4513&amp;service=http://ip:port/ssotest/</span></p>
						<p><span style="font-size: 16px;"><strong><span style="font-size: 16px; font-family: 等线;">返回说明</span></strong></span></p>
						<table width="568">
							<tbody>
								<tr class="firstRow">
									<td width="66" valign="top" style="padding: 0px 7px; border-width: 1px; border-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">序号</span></p>
									</td>
									<td width="194" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-width: 1px; border-top-color: windowtext; border-bottom-width: 1px; border-bottom-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">字段名</span></p>
									</td>
									<td width="63" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-width: 1px; border-top-color: windowtext; border-bottom-width: 1px; border-bottom-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">约束</span></p>
									</td>
									<td width="66" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-width: 1px; border-top-color: windowtext; border-bottom-width: 1px; border-bottom-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">类型</span></p>
									</td>
									<td width="179" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-width: 1px; border-top-color: windowtext; border-bottom-width: 1px; border-bottom-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">说明</span></p>
									</td>
								</tr>
								<tr>
									<td width="66" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">1</span></p>
									</td>
									<td width="194" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">cas:serviceResponse</span></p>
									</td>
									<td width="63" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">必选</span></p>
									</td>
									<td width="66" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;"><br /></td>
									<td width="179" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;"><br /></td>
								</tr>
								<tr>
									<td width="66" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">1.1</span></p>
									</td>
									<td width="194" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">cas:authenticationSuccess</span></p>
									</td>
									<td width="63" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">必选</span></p>
									</td>
									<td width="66" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;"><br /></td>
									<td width="179" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;"><br /></td>
								</tr>
								<tr>
									<td width="66" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">1.1.1</span></p>
									</td>
									<td width="194" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">cas:user</span></p>
									</td>
									<td width="63" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">必选</span></p>
									</td>
									<td width="66" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 14px;">String</span></p>
									</td>
									<td width="179" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">用户标识ID</span></p>
									</td>
								</tr>
								<tr>
									<td width="66" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">1.1.2</span></p>
									</td>
									<td width="194" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">cas:attributes</span></p>
									</td>
									<td width="63" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">必选</span></p>
									</td>
									<td width="66" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;"><br /></td>
									<td width="179" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;"><br /></td>
								</tr>
								<tr>
									<td width="66" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">1.1.2.1</span></p>
									</td>
									<td width="194" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">cas:truename</span></p>
									</td>
									<td width="63" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">必选</span></p>
									</td>
									<td width="66" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 14px;">String</span></p>
									</td>
									<td width="179" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">用户真实名称</span></p>
									</td>
								</tr>
								<tr>
									<td width="66" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">1.1.2.2</span></p>
									</td>
									<td width="194" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">cas:mobile</span></p>
									</td>
									<td width="63" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">可选</span></p>
									</td>
									<td width="66" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 14px;">String</span></p>
									</td>
									<td width="179" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">手机号</span></p>
									</td>
								</tr>
								<tr>
									<td width="66" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">1.1.2.3</span></p>
									</td>
									<td width="194" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">cas:usertype</span></p>
									</td>
									<td width="63" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">必选</span></p>
									</td>
									<td width="66" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 14px;">String</span></p>
									</td>
									<td width="179" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">用户类型</span></p>
									</td>
								</tr>
								<tr>
									<td width="66" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">1.1.2.4</span></p>
									</td>
									<td width="194" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">cas:schoolgroupid</span></p>
									</td>
									<td width="63" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">必选</span></p>
									</td>
									<td width="66" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">String</span></p>
									</td>
									<td width="179" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">用户学校分组标识ID</span></p>
									</td>
								</tr>
								<tr>
									<td width="66" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">1.1.2.5</span></p>
									</td>
									<td width="194" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">cas:password</span></p>
									</td>
									<td width="63" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">可选</span></p>
									</td>
									<td width="66" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">String</span></p>
									</td>
									<td width="179" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">用户密码</span></p>
									</td>
								</tr>
								<tr>
									<td width="66" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">1.1.2.6</span></p>
									</td>
									<td width="194" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">cas:schoolid</span></p>
									</td>
									<td width="63" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">必选</span></p>
									</td>
									<td width="66" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">String</span></p>
									</td>
									<td width="179" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">归属机构\学校ID</span></p>
									</td>
								</tr>
								<tr>
									<td width="66" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">1.1.2.7</span></p>
									</td>
									<td width="194" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">cas:name</span></p>
									</td>
									<td width="63" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">必选</span></p>
									</td>
									<td width="66" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">String</span></p>
									</td>
									<td width="179" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">用户名</span></p>
									</td>
								</tr>
								<tr>
									<td width="66" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">1.1.2.8</span></p>
									</td>
									<td width="194" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">cas:groupleader</span></p>
									</td>
									<td width="63" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">可选</span></p>
									</td>
									<td width="66" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">String</span></p>
									</td>
									<td width="179" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">是否是组长</span></p>
									</td>
								</tr>
								<tr>
									<td width="66" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">1.1.2.9</span></p>
									</td>
									<td width="194" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">cas:registertype</span></p>
									</td>
									<td width="63" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">可选</span></p>
									</td>
									<td width="66" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">String</span></p>
									</td>
									<td width="179" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">用户注册方式</span></p>
									</td>
								</tr>
								<tr>
									<td width="66" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">1.1.2.10</span></p>
									</td>
									<td width="194" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">cas:id</span></p>
									</td>
									<td width="63" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">必选</span></p>
									</td>
									<td width="66" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">String</span></p>
									</td>
									<td width="179" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">用户标识ID，与1.1.1一致</span></p>
									</td>
								</tr>
								<tr>
									<td width="66" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">1.1.2.11</span></p>
									</td>
									<td width="194" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">cas:usercode</span></p>
									</td>
									<td width="63" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">可选</span></p>
									</td>
									<td width="66" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">String</span></p>
									</td>
									<td width="179" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">用户编码</span></p>
									</td>
								</tr>
								<tr>
									<td width="66" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">1.1.2.12</span></p>
									</td>
									<td width="194" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">cas:platmark</span></p>
									</td>
									<td width="63" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">必选</span></p>
									</td>
									<td width="66" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">String</span></p>
									</td>
									<td width="179" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">用户平台ID</span></p>
									</td>
								</tr>
								<tr>
									<td width="66" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">1.1.2.13</span></p>
									</td>
									<td width="193" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">cas:email</span></p>
									</td>
									<td width="63" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">可选</span></p>
									</td>
									<td width="66" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">String</span></p>
									</td>
									<td width="180" valign="top" colspan="2" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">用户邮件地址</span></p>
									</td>
								</tr>
							</tbody>
						</table>
						<p><span style="font-family: 等线; font-size: 16px;">示例：验证通过报文</span></p>
						<table width="568">
							<tbody>
								<tr class="firstRow">
									<td width="568" valign="top" style="padding: 0px 7px; border-width: 1px; border-color: windowtext; background: rgb(238, 236, 225);">
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 16px;">&lt;cas:serviceResponse
												xmlns:cas=&quot;http://www.yale.edu/tp/cas&quot;&gt;</span></p>
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 16px;">&lt;cas:authenticationSuccess&gt;</span></p>
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 14px;">&lt;!—用户ID --&gt;</span></p>
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 16px;">&lt;cas:user&gt;6769bc63-636e-423b-9d19-e6b8a9f13227&lt;/cas:user&gt;</span></p>
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 14px;">&lt;!—附加信息 --&gt;</span></p>
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 16px;">&lt;cas:attributes&gt;</span></p>
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 14px;">&lt;!—用户真实名称 --&gt;</span></p>
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 14px;">&lt;cas:truename&gt;XXXX&lt;/cas:truename&gt;&nbsp;</span></p>
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 14px;">&lt;!—手机号 --&gt;</span></p>
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 16px;">&lt;cas:mobile/&gt;</span></p>
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 14px;">&lt;!—用户类型 --&gt;</span></p>
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 16px;">&lt;cas:usertype&gt;e9f46d6c-c44d-463b-a6ff-80b74cd3edee&lt;/cas:usertype&gt;</span></p>
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 16px;">&lt;!用户学校分组标识ID --&gt;</span></p>
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 16px;">&lt;cas:schoolgroupid/&gt;</span></p>
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 16px;">&lt;cas:password/&gt;</span></p>
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 14px;">&lt;!—归属机构\学校ID --&gt;</span></p>
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 16px;">&lt;cas:schoolid&gt;a57f69f6-89ff-4a1d-aa69-799d5db1b15d&lt;/cas:schoolid&gt;</span></p>
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 14px;">&lt;!—用户名 --&gt;</span></p>
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 16px;">&lt;cas:name&gt;jssadmin&lt;/cas:name&gt;</span></p>
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 14px;">&lt;!—是否是组长 --&gt;</span></p>
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 16px;">&lt;cas:groupleader&gt;0&lt;/cas:groupleader&gt;</span></p>
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 14px;">&lt;!—用户注册方式 --&gt;</span></p>
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 16px;">&lt;cas:registertype&gt;0&lt;/cas:registertype&gt;</span></p>
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 14px;">&lt;!—用户ID --&gt;</span></p>
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 16px;">&lt;cas:id&gt;6769bc63-636e-423b-9d19-e6b8a9f13227&lt;/cas:id&gt;</span></p>
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 16px;">&lt;cas:usercode/&gt;</span></p>
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 14px;">&lt;!—用户平台ID --&gt;</span></p>
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 16px;">&lt;cas:platmark&gt;e7fcf0e4-6fd7-49a0-9343-847d86be2822&lt;/cas:platmark&gt;</span></p>
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 14px;">&lt;!—用户邮箱 --&gt;</span></p>
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 16px;">&lt;cas:email/&gt;</span></p>
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 16px;">&lt;cas:username&gt;6769bc63-636e-423b-9d19-e6b8a9f13227&lt;/cas:username&gt;</span></p>
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 16px;">&lt;/cas:attributes&gt;</span></p>
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 14px;">&lt;!—附加结束 --&gt;</span></p>
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 16px;">&lt;/cas:authenticationSuccess&gt;</span></p>
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 16px;">&lt;/cas:serviceResponse&gt;</span></p>
										<p><span style="font-family: 微软雅黑; font-size: 16px;">&nbsp;</span></p>
									</td>
								</tr>
							</tbody>
						</table>
						<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 16px;">&nbsp;</span></p>
						<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 16px;">验证不通过的报文：</span></p>
						<table width="568">
							<tbody>
								<tr class="firstRow">
									<td width="568" valign="top" style="padding: 0px 7px; border-width: 1px; border-color: windowtext; background: rgb(238, 236, 225);">
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 16px;">&lt;cas:serviceResponse
												xmlns:cas=&quot;http://www.yale.edu/tp/cas&quot;&gt;</span></p>
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 16px;">&lt;cas:authenticationFailure
												code=&quot;INVALID_REQUEST&quot;&gt;必须同时提供&#39;service&#39;和&#39;ticket&#39;参数&lt;/cas:authenticationFailure&gt;</span></p>
										<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 16px;">&lt;/cas:serviceResponse&gt;</span></p>
									</td>
								</tr>
							</tbody>
						</table>
						<p style="text-indent:28px"><span style="font-family: 微软雅黑; font-size: 16px;">&nbsp;</span></p>
						<p style="text-indent:32px"><span style="font-family: 等线; font-size: 16px;">客户端解释XML报文对验证通过的报文中取得<span style="font-size: 16px; font-family: 宋体;">&lt;cas:id&gt;</span>节点的值，得到当前登录的用户标识<span
								style="font-size: 16px; font-family: 宋体;">ID</span>。</span></p>
						<p style="text-indent:32px"><span style="font-family: 等线; font-size: 16px;">客户端收到校验通过后的报文，取得用户标识ID，根据<span style="font-size: 16px; font-family: 宋体;">ID</span>建立本地的用户会话。会话时间默认的保持时间为<span
								style="font-size: 16px; font-family: 宋体;">30</span>分钟；或根据需要具体配置，客户端再根据<span style="font-size: 16px; font-family: 宋体;">ID</span>调用平台接口取得当前用户的用户信息。</span></p>
						<p><br /></p>
						<h2><span style="font-size: 16px;"><span style="font-family: &#39;等线 Light&#39;;">2.2.&nbsp;</span><strong><span
									style="font-family: &#39;等线 Light&#39;;">OAuth接口</span></strong><strong><span style="font-family: &#39;等线 Light&#39;;"></span></strong></span></h2>
						<p><span style="font-family: 等线; font-size: 16px;">XX用户平台集成了OAuth接口，用于提供给非WEB应用系统。非WEB应用系统可以使用OAuth接口，访问平台的资源数据。</span></p>
						<p style="text-indent:32px"><span style="font-family: 宋体; font-size: 16px;">&nbsp;</span></p>
						<h3><span style="font-size: 16px;"><span style="font-family: 等线;">2.2.1.&nbsp;</span><strong><span style="font-family: 等线;">OAuth接口的使用</span></strong><strong><span
									style="font-family: 等线;"></span></strong></span></h3>
						<p><span style="font-family: 等线; font-size: 16px;">平台中封装了OAuth接口的调用。要使用OAuth接口，必须使用平台提供的jar包：</span></p>
						<p><span style="font-family: 等线; font-size: 16px;"><br /></span></p>
						<p><span style="font-family: 等线; font-size: 16px;"></span></p>
						<p style="line-height: 16px;"><img style="vertical-align: middle; margin-right: 2px;" src="images/icon_rar.gif" /><a
							style="color: rgb(0, 102, 204); font-size: 24px; text-decoration: underline;" href="javascript:;" title="middleware.core-1.0.3.A.8-pg.jar.zip"><span
								style="font-size: 24px;">middleware.core-1.0.3.A.8-pg.jar.zip</span></a></p>
						<p><span style="font-family: 等线; font-size: 16px;"></span><br /></p>
						<p><span style="font-family: 等线; font-size: 16px;"><br /></span></p>
						<p><span style="font-family: 等线; font-size: 16px;"><span style="font-size: 16px; font-family: 等线;">系统封装了</span>com.3bu.middle.util.OAuthClient<span
								style="font-size: 16px; font-family: 等线;">对象，用于访问</span>OAuth<span style="font-size: 16px; font-family: 等线;">接口。</span></span></p>
						<p><br /></p>
						<h3><span style="font-size: 16px;"><span style="font-family: 等线;">2.2.2.&nbsp;</span><strong><span style="font-family: 等线;">OAuthClient对象说明</span></strong><strong><span
									style="font-family: 等线;"></span></strong></span></h3>
						<p><span style="font-size: 16px;"><strong><span style="font-size: 16px; font-family: 等线;">构造函数</span></strong></span></p>
						<table width="551">
							<tbody>
								<tr class="firstRow">
									<td width="47" valign="top" style="padding: 0px 7px; border-width: 1px; border-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">序号</span></p>
									</td>
									<td width="104" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-width: 1px; border-top-color: windowtext; border-bottom-width: 1px; border-bottom-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">参数名称</span></p>
									</td>
									<td width="76" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-width: 1px; border-top-color: windowtext; border-bottom-width: 1px; border-bottom-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">数据类型</span></p>
									</td>
									<td width="325" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-width: 1px; border-top-color: windowtext; border-bottom-width: 1px; border-bottom-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">说明</span></p>
									</td>
								</tr>
								<tr>
									<td width="47" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">1</span></p>
									</td>
									<td width="104" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 宋体; font-size: 16px;">serverUrl</span></p>
									</td>
									<td width="76" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">String</span></p>
									</td>
									<td width="325" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">平台服务应用的<span style="font-size: 16px; font-family: 宋体;">URL</span></span></p>
									</td>
								</tr>
								<tr>
									<td width="47" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">2</span></p>
									</td>
									<td width="104" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 宋体; font-size: 16px;">authorizeUrl</span></p>
									</td>
									<td width="76" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">String</span></p>
									</td>
									<td width="325" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-size: 16px;"><span style="font-family: 等线; font-size: 14px;">认证地址，一般是</span><em><span
													style="font-family: &#39;微软雅黑 Light&#39;; font-size: 16px;">/oauth2/authorize</span></em></span></p>
									</td>
								</tr>
								<tr>
									<td width="47" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">3</span></p>
									</td>
									<td width="104" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 宋体; font-size: 16px;">redirectUrl</span></p>
									</td>
									<td width="76" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">String</span></p>
									</td>
									<td width="325" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-size: 16px;"><span style="font-family: 等线; font-size: 14px;">重定向地址，使用<span style="font-size: 16px; font-family: 宋体;">null</span><span
													style="font-size: 16px; font-family: 等线;">的缺省值</span><span style="font-size: 16px; font-family: 宋体;">:</span>&nbsp;</span><span
												style="font-family: 宋体; font-size: 14px;">/oauth2/access_token</span></span></p>
									</td>
								</tr>
							</tbody>
						</table>
						<p style="text-indent:32px"><span style="font-family: 宋体; font-size: 16px;">&nbsp;</span></p>
						<p style="text-indent:32px"><span style="font-family: 宋体; font-size: 16px;"><br /></span></p>
						<p><span style="font-size: 16px;"><strong><span style="font-size: 16px; font-family: 等线;">登录</span></strong></span></p>
						<p style="margin-left:28px"><span style="font-size: 16px;"><span style="font-family: Wingdings; font-size: 16px;">l&nbsp;</span><span
								style="font-family: 等线; font-size: 16px;"><span style="font-size: 16px; font-family: 等线;">方法名</span>: login</span></span></p>
						<p style="margin-left:28px"><span style="font-size: 16px;"><span style="font-family: Wingdings; font-size: 16px;">l&nbsp;</span><span
								style="font-family: 等线; font-size: 16px;"><span style="font-size: 16px; font-family: 等线;">参数说明</span>:</span></span></p>
						<table width="551">
							<tbody>
								<tr class="firstRow">
									<td width="47" valign="top" style="padding: 0px 7px; border-width: 1px; border-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">序号</span></p>
									</td>
									<td width="104" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-width: 1px; border-top-color: windowtext; border-bottom-width: 1px; border-bottom-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">参数名称</span></p>
									</td>
									<td width="76" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-width: 1px; border-top-color: windowtext; border-bottom-width: 1px; border-bottom-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">数据类型</span></p>
									</td>
									<td width="325" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-width: 1px; border-top-color: windowtext; border-bottom-width: 1px; border-bottom-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">说明</span></p>
									</td>
								</tr>
								<tr>
									<td width="47" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">1</span></p>
									</td>
									<td width="104" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 宋体; font-size: 16px;">platformId</span></p>
									</td>
									<td width="76" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">String</span></p>
									</td>
									<td width="325" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">平台<span style="font-size: 16px; font-family: 宋体;">ID</span></span></p>
									</td>
								</tr>
								<tr>
									<td width="47" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">2</span></p>
									</td>
									<td width="104" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 宋体; font-size: 16px;">username</span></p>
									</td>
									<td width="76" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">String</span></p>
									</td>
									<td width="325" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">用户名</span></p>
									</td>
								</tr>
								<tr>
									<td width="47" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">3</span></p>
									</td>
									<td width="104" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 宋体; font-size: 16px;">password</span></p>
									</td>
									<td width="76" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">String</span></p>
									</td>
									<td width="325" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">密码</span></p>
									</td>
								</tr>
								<tr>
									<td width="47" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">4</span></p>
									</td>
									<td width="104" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 宋体; font-size: 16px;">clientId</span></p>
									</td>
									<td width="76" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-size: 16px;"><span style="font-family: 宋体; font-size: 14px;">S</span><span style="font-family: 等线; font-size: 14px;">tring</span></span></p>
									</td>
									<td width="325" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">客户<span style="font-size: 16px; font-family: 宋体;">ID,
												</span>通常使用常量</span></p>
										<p><span style="font-family: 宋体; font-size: 16px;">&quot;6b4cfaea-7016-11e5-bd19-68f728833c05&quot;</span></p>
									</td>
								</tr>
							</tbody>
						</table>
						<p style="text-indent:32px"><span style="font-family: 宋体; font-size: 16px;">&nbsp;</span></p>
						<p style="margin-left:28px"><span style="font-size: 16px;"><span style="font-family: Wingdings; font-size: 16px;">l&nbsp;</span><span
								style="font-family: 等线; font-size: 16px;"><span style="font-size: 16px; font-family: 等线;">返回说明</span>:json类型：</span></span></p>
						<table width="551">
							<tbody>
								<tr class="firstRow">
									<td width="47" valign="top" style="padding: 0px 7px; border-width: 1px; border-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">序号</span></p>
									</td>
									<td width="104" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-width: 1px; border-top-color: windowtext; border-bottom-width: 1px; border-bottom-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">参数名称</span></p>
									</td>
									<td width="76" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-width: 1px; border-top-color: windowtext; border-bottom-width: 1px; border-bottom-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">数据类型</span></p>
									</td>
									<td width="325" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-width: 1px; border-top-color: windowtext; border-bottom-width: 1px; border-bottom-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">说明</span></p>
									</td>
								</tr>
								<tr>
									<td width="47" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">1</span></p>
									</td>
									<td width="104" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 宋体; font-size: 16px;">access_token</span></p>
									</td>
									<td width="76" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">String</span></p>
									</td>
									<td width="325" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">令牌</span></p>
									</td>
								</tr>
							</tbody>
						</table>
						<p style="text-indent:32px"><span style="font-family: 宋体; font-size: 16px;">&nbsp;</span></p>
						<p style="margin-left:28px"><span style="font-size: 16px;"><span style="font-family: Wingdings; font-size: 16px;">l&nbsp;</span><span
								style="font-size: 16px; font-family: 等线;">异常：</span></span></p>
						<p style="text-indent:32px"><span style="font-family: 宋体; font-size: 16px;">HttpException, IOException,
								JSONException, Exception</span></p>
						<p style="text-indent:32px"><span style="font-family: 宋体; font-size: 16px;">&nbsp;</span></p>
						<p><span style="font-size: 16px;"><strong><span style="font-family: 等线; font-size: 16px;"><span style="font-size: 16px; font-family: 等线;">调用</span>POST接口</span></strong></span></p>
						<p style="margin-left:28px"><span style="font-size: 16px;"><span style="font-family: Wingdings; font-size: 16px;">l&nbsp;</span><span
								style="font-family: 等线; font-size: 16px;"><span style="font-size: 16px; font-family: 等线;">方法名</span>: callPost</span></span></p>
						<p style="margin-left:28px"><span style="font-size: 16px;"><span style="font-family: Wingdings; font-size: 16px;">l&nbsp;</span><span
								style="font-family: 等线; font-size: 16px;"><span style="font-size: 16px; font-family: 等线;">参数说明</span>:</span></span></p>
						<table width="551">
							<tbody>
								<tr class="firstRow">
									<td width="46" valign="top" style="padding: 0px 7px; border-width: 1px; border-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">序号</span></p>
									</td>
									<td width="104" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-width: 1px; border-top-color: windowtext; border-bottom-width: 1px; border-bottom-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">参数名称</span></p>
									</td>
									<td width="153" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-width: 1px; border-top-color: windowtext; border-bottom-width: 1px; border-bottom-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">数据类型</span></p>
									</td>
									<td width="249" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-width: 1px; border-top-color: windowtext; border-bottom-width: 1px; border-bottom-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">说明</span></p>
									</td>
								</tr>
								<tr>
									<td width="46" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">1</span></p>
									</td>
									<td width="104" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">postUrl</span></p>
									</td>
									<td width="153" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">String</span></p>
									</td>
									<td width="249" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">API地址</span></p>
									</td>
								</tr>
								<tr>
									<td width="46" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">2</span></p>
									</td>
									<td width="104" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">params</span></p>
									</td>
									<td width="153" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 宋体; font-size: 16px;">Map&lt;String, String&gt;</span></p>
									</td>
									<td width="249" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">参数</span></p>
									</td>
								</tr>
								<tr>
									<td width="46" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">3</span></p>
									</td>
									<td width="104" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">contentType</span></p>
									</td>
									<td width="153" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">String</span></p>
									</td>
									<td width="249" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">数据类型</span></p>
									</td>
								</tr>
							</tbody>
						</table>
						<p><span style="font-size: 16px;"><strong><span style="font-family: 微软雅黑; font-size: 16px;">&nbsp;</span></strong></span></p>
						<p style="margin-left:28px"><span style="font-size: 16px;"><span style="font-family: Wingdings; font-size: 16px;">l&nbsp;</span><span
								style="font-family: 等线; font-size: 16px;"><span style="font-size: 16px; font-family: 等线;">返回类型</span>:</span></span></p>
						<p style="margin-left:57px"><span style="font-family: 微软雅黑; font-size: 16px;">&nbsp;Object<span style="font-size: 16px; font-family: 微软雅黑;">类型</span>,JSONObject/JSONArray，视具体接口而定</span></p>
						<p style="margin-left:28px"><span style="font-size: 16px;"><span style="font-family: Wingdings; font-size: 16px;">l&nbsp;</span><span
								style="font-size: 16px; font-family: 等线;">异常：</span></span></p>
						<p style="margin-left:57px"><span style="font-family: 微软雅黑; font-size: 16px;">IOException</span></p>
						<p style="text-indent:32px"><span style="font-family: 宋体; font-size: 16px;">&nbsp;</span></p>
						<p><span style="font-size: 16px;"><strong><span style="font-family: 等线; font-size: 16px;"><span style="font-size: 16px; font-family: 等线;">调用</span>GET接口</span></strong></span></p>
						<p style="margin-left:28px"><span style="font-size: 16px;"><span style="font-family: Wingdings; font-size: 16px;">l&nbsp;</span><span
								style="font-family: 等线; font-size: 16px;"><span style="font-size: 16px; font-family: 等线;">方法名</span>: callGet</span></span></p>
						<p style="margin-left:28px"><span style="font-size: 16px;"><span style="font-family: Wingdings; font-size: 16px;">l&nbsp;</span><span
								style="font-family: 等线; font-size: 16px;"><span style="font-size: 16px; font-family: 等线;">参数说明</span>:</span></span></p>
						<table width="551">
							<tbody>
								<tr class="firstRow">
									<td width="46" valign="top" style="padding: 0px 7px; border-width: 1px; border-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">序号</span></p>
									</td>
									<td width="103" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-width: 1px; border-top-color: windowtext; border-bottom-width: 1px; border-bottom-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">参数名称</span></p>
									</td>
									<td width="153" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-width: 1px; border-top-color: windowtext; border-bottom-width: 1px; border-bottom-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">数据类型</span></p>
									</td>
									<td width="249" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-width: 1px; border-top-color: windowtext; border-bottom-width: 1px; border-bottom-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">说明</span></p>
									</td>
								</tr>
								<tr>
									<td width="46" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">1</span></p>
									</td>
									<td width="103" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 宋体; font-size: 16px;">getUrl</span></p>
									</td>
									<td width="153" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;"><br /></td>
									<td width="249" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">API地址</span></p>
									</td>
								</tr>
								<tr>
									<td width="46" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">2</span></p>
									</td>
									<td width="103" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">params</span></p>
									</td>
									<td width="153" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 宋体; font-size: 16px;">Map&lt;String, String&gt;</span></p>
									</td>
									<td width="249" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">参数</span></p>
									</td>
								</tr>
								<tr>
									<td width="46" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">3</span></p>
									</td>
									<td width="103" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">contentType</span></p>
									</td>
									<td width="153" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">String</span></p>
									</td>
									<td width="249" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">数据类型</span></p>
									</td>
								</tr>
							</tbody>
						</table>
						<p style="margin-left:84px"><span style="font-size: 16px;"><strong><span style="font-family: 微软雅黑; font-size: 16px;">&nbsp;</span></strong></span></p>
						<p style="margin-left:28px"><span style="font-size: 16px;"><span style="font-family: Wingdings; font-size: 16px;">l&nbsp;</span><span
								style="font-family: 等线; font-size: 16px;"><span style="font-size: 16px; font-family: 等线;">返回类型</span>:</span></span></p>
						<p style="margin-left:57px"><span style="font-family: 微软雅黑; font-size: 16px;">&nbsp;Object<span style="font-size: 16px; font-family: 微软雅黑;">类型</span>,JSONObject/JSONArray，视具体接口而定</span></p>
						<p style="margin-left:28px"><span style="font-size: 16px;"><span style="font-family: Wingdings; font-size: 16px;">l&nbsp;</span><span
								style="font-size: 16px; font-family: 等线;">异常：</span></span></p>
						<p style="margin-left:57px"><span style="font-family: 微软雅黑; font-size: 16px;">IOException</span></p>
						<p><span style="font-size: 16px;"><strong><span style="font-family: 等线; font-size: 16px;"><span style="font-size: 16px; font-family: 等线;">调用</span>GET接口(重载方法)</span></strong></span></p>
						<p style="margin-left:28px"><span style="font-size: 16px;"><span style="font-family: Wingdings; font-size: 16px;">l&nbsp;</span><span
								style="font-family: 等线; font-size: 16px;"><span style="font-size: 16px; font-family: 等线;">方法名</span>: callGet</span></span></p>
						<p style="margin-left:28px"><span style="font-size: 16px;"><span style="font-family: Wingdings; font-size: 16px;">l&nbsp;</span><span
								style="font-family: 等线; font-size: 16px;"><span style="font-size: 16px; font-family: 等线;">参数说明</span>:</span></span></p>
						<table width="551">
							<tbody>
								<tr class="firstRow">
									<td width="46" valign="top" style="padding: 0px 7px; border-width: 1px; border-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">序号</span></p>
									</td>
									<td width="102" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-width: 1px; border-top-color: windowtext; border-bottom-width: 1px; border-bottom-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">参数名称</span></p>
									</td>
									<td width="154" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-width: 1px; border-top-color: windowtext; border-bottom-width: 1px; border-bottom-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">数据类型</span></p>
									</td>
									<td width="249" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-width: 1px; border-top-color: windowtext; border-bottom-width: 1px; border-bottom-color: windowtext; background: rgb(238, 236, 225);">
										<p><span style="font-family: 微软雅黑; font-size: 16px;">说明</span></p>
									</td>
								</tr>
								<tr>
									<td width="46" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">1</span></p>
									</td>
									<td width="102" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 宋体; font-size: 16px;">getUrl</span></p>
									</td>
									<td width="154" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;"><br /></td>
									<td width="249" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">API地址</span></p>
									</td>
								</tr>
								<tr>
									<td width="46" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">2</span></p>
									</td>
									<td width="102" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">params</span></p>
									</td>
									<td width="154" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 宋体; font-size: 16px;">Map&lt;String, String&gt;</span></p>
									</td>
									<td width="249" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">参数</span></p>
									</td>
								</tr>
								<tr>
									<td width="46" valign="top" style="padding: 0px 7px; border-left-width: 1px; border-left-color: windowtext; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">3</span></p>
									</td>
									<td width="102" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">header</span></p>
									</td>
									<td width="154" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 宋体; font-size: 16px;">Map&lt;String, String&gt;</span></p>
									</td>
									<td width="249" valign="top" style="padding: 0px 7px; border-left-style: none; border-right-width: 1px; border-right-color: windowtext; border-top-style: none; border-bottom-width: 1px; border-bottom-color: windowtext;">
										<p><span style="font-family: 等线; font-size: 16px;">HTTP定制<span style="font-size: 16px; font-family: 宋体;">HEAD</span></span></p>
									</td>
								</tr>
							</tbody>
						</table>
						<p style="margin-left:28px"><span style="font-size: 16px;"><span style="font-family: Wingdings; font-size: 16px;">l&nbsp;</span><span
								style="font-family: 等线; font-size: 16px;"><span style="font-size: 16px; font-family: 等线;">返回类型</span>:</span></span></p>
						<p style="margin-left:57px"><span style="font-family: 微软雅黑; font-size: 16px;">&nbsp;Object<span style="font-size: 16px; font-family: 微软雅黑;">类型</span>,JSONObject/JSONArray，视具体接口而定</span></p>
						<p style="margin-left:28px"><span style="font-size: 16px;"><span style="font-family: Wingdings; font-size: 16px;">l&nbsp;</span><span
								style="font-size: 16px; font-family: 等线;">异常：</span></span></p>
						<p style="margin-left:57px"><span style="font-family: 微软雅黑; font-size: 16px;">IOException</span></p>
						<p><br /></p>
						<h2><span style="font-size: 16px;"><span style="font-family: &#39;等线 Light&#39;;">2.3.&nbsp;</span><strong><span
									style="font-family: &#39;等线 Light&#39;;">调用示例</span></strong><strong><span style="font-family: &#39;等线 Light&#39;;"></span></strong></span></h2>
						<p style="text-indent:28px"><span style="font-size: 16px;"><strong><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">import</span></em></strong><em><span
									style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">&nbsp;java.util.HashMap;</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><strong><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">import</span></em></strong><em><span
									style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">&nbsp;java.util.Map;</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">&nbsp;</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><strong><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">import</span></em></strong><em><span
									style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">&nbsp;javax.ws.rs.core.MediaType;</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">&nbsp;</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><strong><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">import</span></em></strong><em><span
									style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">&nbsp;net.sf.json.JSONArray;</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><strong><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">import</span></em></strong><em><span
									style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">&nbsp;net.sf.json.JSONObject;</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">&nbsp;</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><strong><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">import</span></em></strong><em><span
									style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">&nbsp;com.3bu.middle.util.OAuthClient</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">&nbsp;</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">/**</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">&nbsp;*
									</span></em><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">OAuth访问示例类</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">*/</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><strong><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">public
											class</span></em></strong><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">&nbsp;TestCenterOAuthCaller
										{</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em> </em><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">//平台服务应用的URL</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em> </em><strong><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">final
											static</span></em></strong><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">&nbsp;String
										ServiceApp = </span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em> </em><em> </em><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">&quot;https://dev.3bu.com:8443/middlecenter&quot;;
										&nbsp;</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em> </em><strong><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">final
											static</span></em></strong><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">&nbsp;String
										AuthorizeUrl=&quot;/oauth2/authorize&quot;;</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em> </em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em> </em><strong><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">public
											static void</span></em></strong><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">&nbsp;main(String[]
										args) &nbsp;</span></em><strong><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">throws</span></em></strong><em><span
									style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">&nbsp;Exception {</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em> </em><em> </em><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">OAuthClient
										client=new OAuthClient(ServiceApp, AuthorizeUrl, null);</span></em></span></p>
						<p style="margin-left:56px;text-indent:28px"><span style="font-size: 16px;"><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">//平台租户ID</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em> </em><em> </em><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">String
										platformId = &quot;29a5350d-aee2-4c30-894e-47124f4c6084&quot;; </span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em> </em><em> </em><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">String
										username = &quot;xxxx&quot;; </span></em><em> </em><em> </em><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">//用户名</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em> </em><em> </em><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">String
										password = &quot;yyyyyy&quot;; &nbsp;&nbsp;</span></em><em> </em><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">//密码</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em> </em><em> </em><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">String
										clientID = &quot;6b4cfaea-7016-11e5-bd19-68f728833c05&quot; ; //常量</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em> </em><em> </em><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">JSONObject
										json = client.login(</span></em></span></p>
						<p style="margin-left:84px;text-indent:28px"><span style="font-size: 16px;"><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">platformId,
										username, password, clientID);</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">&nbsp;</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em> </em><em> </em><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">//API路径</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em> </em><em> </em><strong><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">final
										</span></em></strong><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">String
										apiUrl =&quot;/service/api/v1/auth/area/areaTree/&quot;;</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em> </em><em> </em><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">Map&lt;String,String&gt;
										para = new HashMap&lt;&gt;();</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em> </em><em> </em><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">para.put(&quot;nodeid&quot;,
										&quot;1&quot;);</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em> </em><em> </em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em> </em><em> </em><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">JSONArray</span></em><em><span
									style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">&nbsp;</span></em><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">data=(JSONArray)client.callPost(</span></em></span></p>
						<p style="margin-left:84px;text-indent:28px"><span style="font-size: 16px;"><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">apiUrl,
										para, MediaType.APPLICATION_FORM_URLENCODED);</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em> </em><em> </em><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">System.out.println(data);</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em> </em><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">}</span></em></span></p>
						<p style="text-indent:28px"><span style="font-size: 16px;"><em><span style="font-family: 微软雅黑; color: rgb(128, 128, 128); font-size: 14px;">}</span></em></span></p>
						<p><span style="font-family: 等线; font-size: 16px;">&nbsp;</span></p>
						<p><br /></p>
					</div>


				</div>
			</div>
		</section>


		<v-footer></v-footer>
	</div>
</template>

<script>
	import Header from '../../components/header.vue';
	import Hfooter from '../../components/Hfooter.vue';
	import Hbanner from '../../components/banner.vue';
	export default {
		name: 'servicedevelopment',
		components: {
			'v-header': Header,
			'v-footer': Hfooter,
			'v-banner': Hbanner
		}

	};
</script>

<style lang="stylus" scoped="scoped">
	.cont-text img{ 
		max-width :100%
	}
</style>
