<template>
	<div >
		<v-header></v-header>
		<v-banner></v-banner>
		
		
		<section>
			<div class="question">
				<div class="about-title">
					<h2>company culture</h2>
					<p class="title_cn">企业文化</p>
				</div>
			</div>
		
			<div class="about about-culture">
				<div class="container">
					<div class="culture">
						<ul class="clearfix">
							<li class="culture-list table">
								<div class="table-cell">
									<i></i>
									<h2>品牌口号</h2>
									<p>畅享教育，步步互联</p>
								</div>
							</li>
							<li class="culture-list table">
								<div class="table-cell">
									<i></i>
									<h2>企业定位</h2>
									<p>教育多媒体应用平台技术及服务提供商</p>
								</div>
							</li>
							<li class="culture-list table">
								<div class="table-cell">
									<i></i>
									<h2>企业理念</h2>
									<p>立足教育，专注信息化，坚持创新与服务</p>
								</div>
							</li>
							<li class="culture-list table">
								<div class="table-cell">
									<i></i>
									<h2>企业愿景</h2>
									<p>开创教育云视频融合应用先河，构建涵盖“教、学、管、研”多种教育教学应用的多媒体云平台，促进教育均衡，为国家教育信息化做出贡献</p>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
		
		
		
<!-- 		<div class="culturetitle">
			<span>COMPANY CULTURE</span>
			<p>企业文化</p>
		</div> -->
		
		
		
<!-- 		<div class="cultureList" >
			<ul>
				<li>
					<img src="../assets/images/cultureListImg_03.png" />
					<p>品牌口号2</p>
				</li>
				<li>客户无忧 优质成果</li>
			</ul>
			<ul>
				<li>
					<img src="../assets/images/cultureListImg_03.png" />
					<p>品牌口号2</p>
				</li>
				<li>客户无忧 优质成果</li>
			</ul>
			<ul>
				<li>
					<img src="../assets/images/cultureListImg_03.png" />
					<p>品牌口号2</p>
				</li>
				<li>客户无忧 优质成果</li>
			</ul>
			<ul>
				<li>
					<img src="../assets/images/cultureListImg_03.png" />
					<p>品牌口号2</p>
				</li>
				<li>客户无忧 优质成果</li>
			</ul>
		</div> -->
		
		<v-footer></v-footer>
	</div>
</template>

<script>
	
	import Header from '../../components/header.vue';
	import Hfooter from '../../components/Hfooter.vue';
	import Hbanner from '../../components/banner.vue';
	
	export default{
		name:'culture',
		// data () {
		// 	return{
		// 		iswebshow:true,
		// 		isandrioShow:false,
		// 		width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
		// 	};
		// },
		components:{
			'v-header' : Header,
			'v-footer' : Hfooter,
			'v-banner' : Hbanner
		}
		

	};
</script>

<style lang="stylus" scoped>
 // @import '../assets/css/pages.css';
  
 //  .webShow{
	// display :none;
 //  }
 //  .andrioShow{
	// display :none;
 //  }
</style>
