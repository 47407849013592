import { render, staticRenderFns } from "./NewsList.vue?vue&type=template&id=15c59414&scoped=true&"
import script from "./NewsList.vue?vue&type=script&lang=js&"
export * from "./NewsList.vue?vue&type=script&lang=js&"
import style0 from "./NewsList.vue?vue&type=style&index=0&id=15c59414&lang=stylus&scoped=scoped&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15c59414",
  null
  
)

export default component.exports