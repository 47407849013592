import Vue from 'vue';  // 引入VUE核心库
import App from './App.vue'; // 引入一个当前目录下的名字为App.vue的组件


import './registerServiceWorker';
import router from './router';
import store from './store';

import { getConfigKey } from "@/api/config";


import jQuery from 'jquery';
//import WOW from 'wowjs';
import WOW from './assets/js/wow'

import Swiper from 'swiper';
import 'swiper/dist/css/swiper.min.css';

import VueSuperSlide from 'vue-superslide';

Vue.use(VueSuperSlide);

import ElementUI from 'element-ui'; //element-ui的全部组件
import 'element-ui/lib/theme-chalk/index.css';//element-ui的css
Vue.use(ElementUI); //使用elementUI


import ba from 'vue-ba'

Vue.use(ba, "5c844fea4d82771cbf8d6bc3793a4ee0");
Vue.use(ba, { siteId: "5c844fea4d82771cbf8d6bc3793a4ee0" });



Vue.config.productionTip = false; // 是否要在生产环境当中给予提示功能。
//Vue.use(directive)
Vue.use(WOW);
Vue.prototype.$WOW = WOW;
Vue.prototype.getConfigKey = getConfigKey
Vue.prototype.$IconBus = new Vue();

new Vue({
    router,
    store,
    jQuery,
    Swiper,
    WOW,
    render: h => h(App)  // 渲染函数,将组件App渲染到挂载的元素上。
}).$mount('#app'); //这个 #app 是挂载在 public 主页面上的，你打开index 就会看到 这个元素 id
