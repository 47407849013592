<template>
	<div>
		<v-header></v-header>
		<v-banner></v-banner>


		<section>

			<div class="question">
				<div class="about-title">
					<h2>{{aboutData.titleEnglish}}</h2>
					<p class="title_cn">{{aboutData.title}}</p>
				</div>
			</div>

			<div class="about">
				<div class="container" v-html="aboutData.body">

        </div>
			</div>
		</section>

		<!-- page分页 -->
<!--		<div class="page">-->
<!--			<ul class="pagination">-->
<!--				<li class="disabled"><span>&laquo;上一页</span></li>-->
<!--				&lt;!&ndash; <li class="active"><span>1</span></li> &ndash;&gt;-->
<!--				<li v-for="(pages, index) in page" :key="index"  :class="{ 'active': active == pages}">-->
<!--					<a href="javascript:;">{{pages}}</a>-->
<!--				</li>-->
<!--				<li><a href="javascript:;">下一页&raquo;</a></li>-->
<!--			</ul>-->
<!--		</div>-->


		<v-footer></v-footer>
	</div>
</template>

<script>
	import Header from '../../components/header.vue';
	import Hfooter from '../../components/Hfooter.vue';
	import Hbanner from '../../components/banner.vue';

	import {getAboutDetails} from  '../../api/pageApi.js'



	export default{
		name:'aboutintroduce',
    data(){
		  return{
		    aboutData:{},
        aboutId:0,
      }
    },
		components:{
			'v-header': Header,
			'v-footer': Hfooter,
			'v-banner': Hbanner
		},
    created() {

      this.aboutId = this.$route.query.id
    },
    mounted() {

		  this.getDetails();
    },
    methods:{
      getDetails(){

        var aboutid = this.aboutId;
        var id = aboutid
        getAboutDetails(id).then(res => {
          console.log(res)
          this.aboutData = res.data;
        })
      }
    },
    watch:{
		  $route(to){
        this.aboutId = to.query.id;
        this.getDetails();
      }
    }

	};
</script>

<style lang="stylus" scoped="scoped">
	// @import "../assets/css/pages.css"

</style>
