<template>
  <div id="app">

    <!-- 显示的是当前路由地址所对应的内容 -->
    <router-view/>
  </div>
</template>


<script>


  import header from "/src/components/header";

  export default {
    name: 'App',

    // mounted() {
    //
    // }
  };
</script>


<style lang="scss">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  #nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }
</style>
<!-- // import './src/assets/css/reset.css'
// import './src/assets/css/animate.css'
// import './src/assets/css/swiper.css'

// import './src/assets/css/animate.css'
// import './src/assets/css/index.css' -->


<style lang="stylus">
  .swiper-container2 {
    overflow: hidden;
  }

  .swiper-button-prev {
    left: -50px;
    top: 47%
  }

  .swiper-button-next {
    right: -50px;
    top: 47%
  }
</style>
