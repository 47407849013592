<template>

  <section class="tab">
    <div id="here" class="tab-item">
      <div class="container table hd">
        <ul class="clearfix table-cell">
          <li class="tab-list">
            <i></i>
            <h2>考试平台</h2>
          </li>
          <li class="tab-list">
            <i></i>
            <h2>教学考务系统</h2>
          </li>
          <li class="tab-list">
            <i></i>
            <h2>在线教学系统</h2>
          </li>
          <li class="tab-list">
            <i></i>
            <h2>高校场馆管理系统</h2>
          </li>
          <li class="tab-list">
            <i></i>
            <h2>教育决策分析系统</h2>
          </li>
          <!-- <li class="tab-list">
            <i></i>
            <h2>班班录</h2>
          </li> -->
        </ul>
        <div class="navbkg"></div>
      </div>
    </div>

    <div class="tab-cont">
      <div class="table">
        <ul class="table-cell bd">
          <li class="tab-cont-list">
            <div class="pro">
              <img class="wow fadeInLeft" data-wow-delay=".8s" data-wow-duration='1.5s' src="images/tab1-bg1.png">
              <img class="wow zoomIn2" data-wow-delay="1.6s" data-wow-duration='2.2s' src="images/tab1-bg2.png">
              <img class="wow fadeInUp" data-wow-duration='1.5s' src="images/tab1-bg3.png">
            </div>
            <div class="intro">
              <div class="wow fadeInDown" data-wow-delay="2s" data-wow-duration='1s'>
                <h2>考试练习</h2>
                <span>开放、融合、易用</span>
              </div>
              <em class="wow fadeInUp" data-wow-delay="2s" data-wow-duration='1s'>无忧果考试服务平台</em>
              <p class="wow fadeInUp" data-wow-delay="2.6s" data-wow-duration='1s'>正式考试、模拟考试、练习卷、调查问卷多种试卷类型；单选、多选、判断、填空等多种题型；智能造卷、成绩管理、答题统计。</p>
               <div class="more wow fadeInUp" data-wow-delay="3.1s" data-wow-duration='1s'><a href="/Exam">了解更多</a></div>
            </div>
          </li>

          <li class="tab-cont-list">
            <div class="pro">
              <img class="wow zoomIn" data-wow-delay=".5s" data-wow-duration='1s' src="../../assets/images/product/teach/teach_01.png">
            </div>
            <div class="intro">
              <div class="wow fadeInDown" data-wow-delay="1s" data-wow-duration='1s'>
                <h2>教学考务</h2>
                <span>师生管理利器</span>
              </div>
              <em class="wow fadeInUp" data-wow-delay="1s" data-wow-duration='1s'>无忧果教学考务系统</em>
              <p class="wow fadeInUp" data-wow-delay="1.6s" data-wow-duration='1s'>教学考务管理，支持多校区。专业管理、班级管理、学期阶段管理、课程管理、班级作业、班级考试、考试考务、通知公告、教职工在线办公。</p>
               <div class="more wow fadeInUp" data-wow-delay="2.1s" data-wow-duration='1s'><a href="/Teach">了解更多</a></div>
            </div>
          </li>

          <li class="tab-cont-list">
            <div class="pro">
              <img class="wow fadeInLeft" data-wow-delay="" data-wow-duration='1.5s' src="../../assets/images/schoolonline_15.png">
            </div>
            <div class="intro" style="margin-top: 4%;">
              <div class="wow fadeInDown" data-wow-delay="1s" data-wow-duration='1s'>
                <h2>为企业提供</h2>
                <span>平台+课程+运营</span>
              </div>
              <em class="wow fadeInUp" data-wow-delay="1s" data-wow-duration='1s'>无忧果在线教学系统</em>
              <p class="wow fadeInUp" data-wow-delay="1.5s" data-wow-duration='1s'>个人、机构入驻；课程营销、名师授课、便捷学习，多样化的互动教学,功能丰富。</p>
               <div class="more wow fadeInUp" data-wow-delay="1.9s" data-wow-duration='1s'><a href="/School">了解更多</a></div>
            </div>
          </li>

          <li class="tab-cont-list">
            <div class="pro">
              <img class="wow zoomIn" data-wow-delay="" data-wow-duration='1.5s' src="../../assets/images/solution/stadium/stadium_02.png">
            </div>
            <div class="intro">
              <div class="wow fadeInDown" data-wow-delay="2s" data-wow-duration='1s'>
                <h2>场地管理</h2>
                <span>提升其服务水平和管理效率</span>
              </div>
              <em class="wow fadeInUp" data-wow-delay="2s" data-wow-duration='1s'>无忧果高校场馆管理系统</em>
              <p class="wow fadeInUp" data-wow-delay="2.6s" data-wow-duration='1s'>高校体育场馆包括羽毛球馆、篮球场、网球场、足球场、乒乓球场、健身房等，是学校集中为在校师生提供体育运动服务的场所。</p>
               <div class="more wow fadeInUp" data-wow-delay="3.1s" data-wow-duration='1s'><a href="/Stadium">了解更多</a></div>
            </div>
          </li>

          <li class="tab-cont-list">
            <div class="pro">
              <img class="wow fadeIn2" data-wow-delay="" data-wow-duration='2s' src="../../assets/images/solution/ei/ei_02.png">
            </div>
            <div class="intro">
              <div class="wow fadeInDown" data-wow-delay="1s" data-wow-duration='1s'>
                <h2>智能分析</h2>
                <span>提炼决策指标</span>
              </div>
              <em class="wow fadeInUp" data-wow-delay="1s" data-wow-duration='1s'>教育决策分析系统</em>
              <p class="wow fadeInUp" data-wow-delay="1.6s" data-wow-duration='1s'>构建数据仓库，从人、财、物、教学、科研等不同视角、不同的维度，对高校现有数据进行整合归纳、深度分析，提炼出高校管理决策者最关心的指标，形成全方位立体的信息化系统，提高高校管理人员决策的及时性及准确率。</p>
              <!-- <div class="more wow fadeInUp" data-wow-delay="2.1s" data-wow-duration='1s'><a href="javascript:;">了解更多</a></div> -->
            </div>
          </li>

        </ul>
      </div>
    </div>
  </section>

</template>

<script>

  // 引入插件

  export default {
    name: 'hometab',


    mounted() {  //页面初始化方法调用
      //this.listTitle();
      // shoppath();
    },
    watch: { //监听值变化:map值

    },
    methods: {  //监听方法click事件等，执行drawFeatures方法定义
      shoppath() {
        this.$router.push('/shop');
      },
      schoolpath() {
        this.$router.push('/schoolonline');
        // var schoolurl = 'http://39.108.49.151:8088/index';
        // window.location.href = schoolurl;
      },
      OApath() {
        // var oaurl = 'http://39.108.49.151:9600';
        // window.location.href = oaurl;
        this.$router.push('/OAenterprise');
      },
      homepath() {
        this.$router.push('/Homeintellect');
      }

    },

  };
</script>

<style scoped="scoped">

  .cursorHand {
    cursor: pointer
  }
</style>
