<template>
  <div class="home">
    <!-- <Header  class="Width-100"  style="background: #000000"></Header> -->
    <Header :styleObj="styleObj" @feedback="showFeedback"></Header>
    <article>
      <banner></banner>
      <webtab></webtab>
      <!--		<mobletab></mobletab>-->
      <solution></solution>

      <section class="team">
        <div class="title">
          <h2>专业的研发团队 优质的现场服务</h2>
          <em>更多更高级别服务功能，延伸并拓展现有系统的使用范围，不断满足用户日益增长的服务需求</em>
        </div>

        <div class="map">
          <div class="map-container">
            <div class="location-container clearfix">
              <div class="location location-jilin wow zoomIn" data-wow-duration='1s'>
                <span class="location-name">吉林</span>
                <span class="location-point"></span>
              </div>
              <div class="location location-henan wow zoomIn" data-wow-duration='1s'>
                <span class="location-name">河南</span>
                <span class="location-point"></span>
              </div>
              <div class="location location-beijing wow zoomIn" data-wow-duration='1s'>
                <span class="location-name">北京</span>
                <span class="location-point"></span>
              </div>
              <div class="location location-shandong wow zoomIn" data-wow-duration='1s'>
                <span class="location-name">山东</span>
                <span class="location-point"></span>
              </div>
              <div class="location location-shanghai wow zoomIn" data-wow-duration='1s'>
                <span class="location-name">上海</span>
                <span class="location-point"></span>
              </div>
              <div class="location location-jiangsu wow zoomIn" data-wow-duration='1s'>
                <span class="location-name">江苏</span>
                <span class="location-point"></span>
              </div>
              <div class="location location-zhejiang wow zoomIn" data-wow-duration='1s'>
                <span class="location-name">浙江</span>
                <span class="location-point"></span>
              </div>
              <div class="location location-hubei wow zoomIn" data-wow-duration='1s'>
                <span class="location-name">湖北</span>
                <span class="location-point"></span>
              </div>
              <div class="location location-hunan wow zoomIn" data-wow-duration='1s'>
                <span class="location-name">湖南</span>
                <span class="location-point"></span>
              </div>
              <div class="location location-yunnan wow zoomIn" data-wow-duration='1s'>
                <span class="location-name">云南</span>
                <span class="location-point"></span>
              </div>
              <div class="location location-neimeng wow zoomIn" data-wow-duration='1s'>
                <span class="location-point"></span>
                <span class="location-name">内蒙</span>
              </div>
              <div class="location location-sichuan wow zoomIn" data-wow-duration='1s'>
                <span class="location-point"></span>
                <span class="location-name">四川</span>
              </div>
              <div class="location location-xian wow zoomIn" data-wow-duration='1s'>
                <span class="location-point"></span>
                <span class="location-name">陕西</span>
              </div>
              <div class="location location-xinjiang wow zoomIn" data-wow-duration='1s'>
                <span class="location-point"></span>
                <span class="location-name">新疆</span>
              </div>

            </div>
            <img class="pc-map" src="images/map.png">
            <img class="mobile-map" src="images/mobile-map.png">
          </div>
        </div>
      </section>
    </article>
    <homefooter></homefooter>


    <el-dialog title="问题反馈" :visible.sync="isShowFeedback" width="80%" center>
      <Feedback @submited="feedbackHandler"/>
    </el-dialog>

  </div>
</template>


<!-- common js -->
<!-- <script type="text/javascript" src="../assets/js/jquery-1.9.1.min.js"></script>-->


<script>


  //import '@/public/js/jquery-1.9.1.min.js'
  //import jquery from 'jquery' //导入jquery
  // @ is an alias to /src

  import Header from '@/components/header.vue';
  import banner from '@/components/banner.vue';
  import homefooter from '@/components/Hfooter.vue';
  import solution from '/src/views/Home/solution.vue';
  import webtab from '/src/views/Home/webtab.vue';
  import mobletab from '/src/views/Home/mobletab.vue';
  import Feedback from '@/components/Feedback'

  // 通过axios调用 接口
  //import axios from 'axios'

  export default {
    name: 'Home',
    components: {
      Header,
      banner,
      webtab,
      mobletab,
      solution,
      homefooter,
      Feedback
    },
    data() {
      return {
        isShowFeedback:false,
        overflow: '',
        isstyleObj: false,
        styleObj: {
          width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,//兼容性获取屏幕宽度
          height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,//兼容性获取屏幕高度
        }
      };
    },
    methods: {


      showFeedback() {
        this.isShowFeedback = true;
      },
      feedbackHandler() {
        this.isShowFeedback = false;
      },
    },
    mounted() {


      // if(this.width < '1024' ){
      // 	this.width = this.styleObj.width
      // 	this.height = this.styleObj.height

      // 	console.log(this.width)
      // }


    },


  };

</script>


<style lang="stylus" scoped>
  @import '../assets/css/home.css'

  // @import '~@/assets/js/jquery-1.9.1.min.js';
  // .home
  // 	border : 1px solid #0f0
</style>



