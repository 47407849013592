<template>
	<div class="temabout">
		<v-header  class="headerTop"></v-header>
		
		<!-- <v-banner></v-banner> -->
		<div class="listWOW">
			<div class="row">
				<div class="slideInLeft">
					<div class="wow rollIn "><img src="../../assets/images/OA_03.png" /></div>
				</div>
				
				<div class="slideInRight">
					<div class="wow bounceInDown "><img src="../../assets/images/OA_06.png" /></div>
					<div class="wow lightSpeedIn cursorHand  wowMargin" @click="OApath()"> <img src="../../assets/images/OA_10.png" /></div>
				</div>
			</div>
			<div class="row" style="background-color: #50afbd;">
				<div class="slideInLeft">
					<div class="wow bounceInLeft"><img src="../../assets/images/OA_14.png" /></div>
				</div>
				<div class="slideInRightN">
					<div class="wow flipInX"><img src="../../assets/images/OA_17.png"  /></div>
				</div>
			</div>
			<div class="row" style="background-color: #97ddd9;">
				<div class="slideInRightN">
					<div class="wow bounceInLeft"><img src="../../assets/images/OA_22.png" /></div>
				</div>
				<div class="slideInLeft">
					<div class="wow bounceInRight"><img src="../../assets/images/OA_24.png" /></div>
				</div>
			</div>
			<div class="row-column" style="background-color: #50afbd;">
					<div class="wow bounceInDown columnW-max"><img src="../../assets/images/OA_29.png" /></div>
					<div class="wow bounceInUp  wowMargin columnW-min cursorHand" @click="OApath()"> <img src="../../assets/images/OA_33.png" /></div>
			</div>
		</div>
		<v-footer></v-footer>
	</div>
</template>

<script>
	
	import Header from '@/components/header';
	import footer from '@/components/Hfooter';
	// import banner from '@/components/banner';
	
	// import WOW from 'wowjs';
	
	export default{
		name:'OAenterprise',
		components:{
			'v-header':Header,
			'v-footer':footer,
			// 'v-banner':banner
		},
		mounted() {
	
			// new WOW.WOW().init() ;
	
		},
		methods:{
			OApath(){
				var OAurl = 'http://39.108.49.151:9600';
				window.location.href = OAurl;
				// this.$router.push('/内部链接');
			}
		}
	};
</script>

<style lang="stylus" scoped>
 @import '../../assets/css/wowrowlist.css'
 
</style>
